import {
  TextField,
  BooleanInput,
  TextInput,
  EditButton,
  SelectInput,
  required,
  useRecordContext,
  useNotify,
  Toolbar,
  ImageField,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SaveButton,
  useGetRecordId,
  useDelete,
  useRedirect,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
  ReferenceInput,
  NumberInput,
  useRefresh,
  useTranslate,
  DateTimeInput,
  ChipField,
  Create,
  email,
  Link,
} from 'react-admin';
import {
  Datagrid, SimpleForm,
} from '@react-admin/ra-rbac';
import * as React from 'react';
import {
  Autocomplete,
  Typography,
  TextField as MUITextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardContent,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  FormControlLabel,
  DialogActions,
  Avatar,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DangerousIcon from '@mui/icons-material/Dangerous';
import SaveIcon from '@mui/icons-material/Save';
import _, { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  MapContainer, Popup, TileLayer,
} from 'react-leaflet';
import DeleteIcon from '@mui/icons-material/Delete';
import { Map } from 'leaflet';
import { ReferenceOneInput } from '@react-admin/ra-relationships';
import ReactJson from 'react-json-view';
import { ListLive } from '@react-admin/ra-realtime';
import { AssetFilters } from '../components/fields/Filters';
import DateFieldNullable from '../../../components/fields/DateFieldNullable';
import ReferenceFieldNullable from '../../../components/fields/ReferenceFieldNullable';
import {
  getDeviceConfig, getDeviceInfo, logOrConfig, updateDeviceInfo,
} from '../../../dataProviders/appDataProvider';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { AppUserActionButtons, AppUserBulkActionButtons } from '../../../components/buttons/AppUserListButtons';
import { LIST_DEBOUNCE } from '../../../config';
import { PositionForm } from '../../../components/baseForms/PositionForm';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import authProvider from '../../../utils/authProvider';
import { HasRoles } from '../../../components/HasRoles';
import { PageExplanation } from '../../../components/PageExplanation';
import { ContactField } from '../../../components/fields/ContactField';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import ReferenceArrayFieldNullable from '../../../components/fields/ReferenceArrayFieldNullable';
import { DownloadAssetHistoryButton } from '../../../components/buttons/DownloadAssetHistoryButton';
import { androidPermissions, iosPermissions } from '../../../lib/constants/permissionSplit';
import { appModeOptions } from '../../../lib/constants/selectChoices';
import { RegistrationCodePicker } from '../../../components/RegistrationCodePicker';
import { PhoneNumberInput } from '../../../components/inputs/PhoneNumberInput';
import { UsernameInput } from '../../bhvk/components/inputs/UsernameInput';
import { BooleanCheckBoxInput } from '../../bhvk/components/inputs/BooleanCheckBoxInput';
import { CreateToolbarRight, CustomToolbar } from '../../../components/CustomToolBar';
import { ImageInput } from '../../../components/inputs/ImageInput';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';

const Grid = Grid2;

const transform = (data: Record<string, any>) => {

  delete data.telluId;
  delete data.share;
  delete data.insideZones;
  delete data.lastObservationAt;
  delete data.hasApp;
  delete data.propertiesOwnership;
  delete data.username;
  delete data.formattedAddress;
  delete data.userId;

  if (data.assetGroups) {

    data.assetGroups = data.assetGroups.map((group: string) => {

      return {
        _id: group,
        _ref: 'AssetGroup',
      };

    });

  }

  if (data.properties?.image === null) {

    delete data.properties.image;

  }

  if (data.position && data.position.coordinates) {

    if (data.position?.properties?.provider !== 'static') {

      data.position = {
        coordinates: data.position.coordinates || [0, 0],
        ...data.position,
        properties: {
          ...data.position.properties,
          provider: 'gps/none',
        },
      };

    } else {

      data.position = {
        ...data.position,
        type: 'Point',
        properties: {
          ...data.position.properties,
          provider: 'static',
          accuracy: 0,
          address: {
            ...data.position.properties.address,
            formattedAddress: [
              `${data.position.properties.address?.streetName || ''} ${data.position.properties.address?.streetNumber || ''}`.trim(),
              data.position.properties.address?.city || null,
              data.position.properties.address?.state || null,
              data.position.properties.address?.countryCode || null,
            ].filter((val) => (val && val.length > 0)).join(', ').trim(),
          },
          dateTime: new Date().toISOString(),
        },
      };

    }

  } else {

    data.position = null;

  }

  return {
    ...data,
    user: { _ref: 'User', _id: data.user._id },
    staticResponders: data.staticResponders.map((responder:any) => (
      {
        ...responder,
        staticResponder: { _id: responder.staticResponder, _ref: 'StaticResponder' },
        priority: responder.priority || null,
      }
    )),
  };

};

const appUserCreateTransformer = (appUser: any) => {

  return {
    ...appUser,
    available: appUser.available || false,
    username: appUser.username || appUser.properties.email,
    externalId: appUser.externalId,
    properties: {
      ...appUser.properties,
      smsNumber: appUser.properties?.phoneNumber,
      locale: appUser.properties?.locale || 'nl',
    },
  };

};

const DeviceActions = (assetId: any) => {

  const [criticalSwitchState, setCriticalSwitchState] = useState(false);

  const notify = useNotify();
  const translate = useTranslate();

  const sendConfig = async () => {

    const notificationResult = await logOrConfig(assetId.assetId, 'configuration', criticalSwitchState);

    if (notificationResult.status < 400) {

      notify('resources.app-users.text.sent_configuration', { type: 'success' });

    } else {

      notify('resources.app-users.text.sent_configuration_failed', { type: 'error' });

    }

  };

  const requestLog = async (type: string) => {

    const notificationResult = await logOrConfig(assetId.assetId, type, criticalSwitchState);

    if (notificationResult.status < 400) {

      notify('resources.app-users.text.sent_log', { type: 'success' });

    } else {

      notify('resources.app-users.text.sent_log_failed', { type: 'error' });

    }

  };

  const changeCriticalSwitch = (event: any, newValue: boolean) => {

    setCriticalSwitchState(newValue);

  };

  return <Card>
    <CardContent>
      <Typography variant="h6">{translate('resources.app-users.text.actions')}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ verticalAlign: 'top' }}>
              {translate('resources.app-users.text.send_critical_title')}
            </TableCell>
            <TableCell>
              <div className="flex-in-between">
                <Typography variant="body1" sx={{ fontSize: '12px' }}>
                  {translate('resources.app-users.text.send_critical_explanation')}
                </Typography>
                <Switch onChange={changeCriticalSwitch} checked={criticalSwitchState}/>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ verticalAlign: 'top' }}>
              {translate('resources.app-users.text.configuration_update_title')}
            </TableCell>
            <TableCell>
              <Button variant='outlined' onClick={sendConfig} size='small'>{translate('resources.app-users.text.configuration_notification_title')}</Button>
              <Typography variant="body1" sx={{ fontSize: '12px' }}>
                {translate('resources.app-users.text.configuration_notification_explanation')}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ verticalAlign: 'top' }}>
              {translate('resources.app-users.text.log_notification_title')}
            </TableCell>
            <TableCell>
              <div style={{ whiteSpace: 'nowrap' }}>
                <Button variant='outlined' onClick={() => {

                  requestLog('log');

                }} size='small' sx={{ marginRight: '10px' }}>{translate('resources.app-users.text.log_notification_title')}</Button>
                <Button variant='outlined' onClick={ () => {

                  requestLog('devlog');

                }} size='small'>{translate('resources.app-users.text.devlog_notification_title')}</Button>
              </div>
              <Typography variant='body1' sx={{ fontSize: '12px' }}>
                {translate('resources.app-users.text.log_notification_explanation')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CardContent>
  </Card>;

};

const DeviceInfo = (legacyDevice: any) => {

  const [currentDeviceConfig, setCurrentDeviceConfig] = useState();
  const [defaultDeviceConfig, setDefaultDeviceConfig] = useState();
  const [filteredPermissions, setFilteredPermissions] = useState<any[]>([]);
  const record = useRecordContext();
  const translate = useTranslate();
  useEffect(() => {

    if (!record || !record._id) {

      return;

    }
    getDeviceConfig(record._id).then((res) => {

      setCurrentDeviceConfig(res.deviceConfig.config);
      setDefaultDeviceConfig(res.defaultConfig);

    }).catch((e) => {

      console.log(e.message);

    });

  }, [record?._id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!currentDeviceConfig) {

      return;

    }

    const temporaryPermissions: any[] = [];
    const platformPermissionKeys = legacyDevice.profile?.phone?.osName.toLowerCase() === 'android' ? androidPermissions : iosPermissions;
    for (const configKey of platformPermissionKeys) {

      temporaryPermissions.push({ name: configKey, value: currentDeviceConfig['PermissionManager.Status'][configKey] });

    }
    setFilteredPermissions(temporaryPermissions);

  }, [legacyDevice, currentDeviceConfig]);

  if (!record) return null;

  legacyDevice = legacyDevice.legacyDevice;
  if (!defaultDeviceConfig || !currentDeviceConfig) return null;
  const DeviceProfile = () => {

    if (!legacyDevice.profile?.phone) return null;
    return <TableBody>
      {Object.entries(legacyDevice.profile.phone).map((field) => {

        return <TableRow>
          <TableCell>{field[0]}</TableCell>
          <TableCell><div style={{ width: '100%', overflow: 'auto' }}>{field[1] as string}</div></TableCell>
        </TableRow>;

      })
      }
      {Object.entries(legacyDevice.profile.build).map((field) => {

        return <TableRow>
          <TableCell>{field[0]}</TableCell>
          <TableCell><div style={{ width: '100%', overflow: 'auto' }}>{field[1] as string}</div></TableCell>
        </TableRow>;

      })
      }
    </TableBody>;

  };
  return <Card>
    <CardContent>
      <Typography variant="h6">{translate('resources.app-users.text.device_info')}</Typography>
      <Table aria-label="simple table" sx={{ tableLayout: 'fixed' }}>
        <TableBody>
          <TableRow>
            <TableCell>
              {`${translate('resources.app-users.text.installed_version')}:`}
            </TableCell>
            <TableCell>
              {legacyDevice.version}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {`${translate('resources.app-users.text.available_version')}:`}
            </TableCell>
            <TableCell>
              {defaultDeviceConfig['UpdateManager.NewestVersion']}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {`${translate('resources.app-users.text.compliance_state')}:`}
            </TableCell>
            <TableCell>
              {(legacyDevice.version >= defaultDeviceConfig['UpdateManager.NewestVersion'])
                ? <span className="badge badge-success">{translate('resources.app-users.text.compliance_true')}</span>
                : <span className="badge badge-warning">{translate('resources.app-users.text.compliance_false')}</span>}
            </TableCell>
          </TableRow>
          <br/>
          <Typography variant='body1' sx={{ fontWeight: 'bold' }}>{translate('resources.app-users.text.device_profile')}</Typography>
          <TableRow>
            <TableCell sx={{ verticalAlign: 'top' }} colSpan={2}>
              {translate('general.text.profile')}
              <Table sx={{ tableLayout: 'fixed' }}>
                <DeviceProfile/>
              </Table>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ verticalAlign: 'top' }} colSpan={2}>
              {translate('resources.app-users.text.permissions')}
              <Table sx={{ tableLayout: 'fixed' }} >
                <TableBody>
                  {filteredPermissions.map((key, index) => {

                    return <TableRow key={index}>
                      <TableCell>{key.name}</TableCell>
                      <TableCell style={{ padding: '0' }}>{key.value === 'granted'
                        ? <span style={{ color: 'green' }} className="icon-and-text"><CheckBoxIcon /><small>{translate('general.text.true')}</small></span>
                        : <span className="xg-red icon-and-text" ><DangerousIcon /><small>{translate('general.text.false')}</small></span>}
                      </TableCell>
                    </TableRow>;

                  })}
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CardContent>
  </Card>;

};

const AppDeviceConfig = () => {

  const translate = useTranslate();
  const initialState: Array<any> = [];
  const [selectedGroups, setSelectedGroups] = useState(initialState);
  const [availableGroups, setAvailableGroups] = useState(initialState);
  const [currentGroups, setCurrentGroups] = useState(initialState);
  const [currentLegacyDevice, setCurrentLegacyDevice] = useState({
    id: '',
    group: [],
    lastConfigUpdate: undefined,
    lastSync: undefined,
  });
  const [currentXgacDevice, setCurrentXgacDevice] = useState({ _id: '', deviceId: '' });
  const record = useRecordContext();
  const notify = useNotify();
  useEffect(() => {

    if (!record?._id) return;
    getDeviceInfo(record._id).then((res) => {

      setAvailableGroups(res.groups);
      setCurrentLegacyDevice(res.legacyDevice);
      setCurrentXgacDevice(res.xgacDevice);
      setSelectedGroups(res.legacyDevice.group);
      setCurrentGroups(res.legacyDevice.group);

    }).catch((e) => {

      console.log(e.message);

    });

  }, [record?._id]);
  if (!record) return null;

  const DeviceConfigComplies = () => {

    if (currentLegacyDevice.lastSync && currentLegacyDevice.lastConfigUpdate) {

      const lastSync = new Date(currentLegacyDevice.lastSync);
      const lastConfigUpdate = new Date(currentLegacyDevice.lastConfigUpdate);

      return (lastSync > lastConfigUpdate) ? <span className="badge badge-success">{translate('resources.app-users.text.compliance_true')}</span>
        : <span className="badge badge-warning">{translate('resources.app-users.text.compliance_false')}</span>;

    }
    return <span>{translate('general.text.false')}</span>;

  };

  const updateSelectedGroups = (event: SyntheticEvent<Element, Event>, newValue: Array<any>) => {

    setSelectedGroups(newValue);

  };
  const sendSelectedGroups = async () => {

    const updateResult = await updateDeviceInfo(selectedGroups, currentXgacDevice._id);
    if (updateResult.status < 400) {

      notify('resources.app-users.text.saving_group_successful', { type: 'success' });
      setCurrentGroups(selectedGroups);

    } else {

      notify('resources.app-users.text.saving_group_failed', { type: 'error' });

    }

  };

  const resetGroups = () => {

    setSelectedGroups(currentGroups);

  };

  const DateShow = (props: any) => {

    if (!props.source) return null;
    return <div>{new Date(props.source).toLocaleString()}</div>;

  };

  return (<><Card>
    <CardContent>
      <div className="flex-in-between">
        <Typography variant='h6'>
          {translate('resources.app-users.text.config')}
        </Typography>
        <HasRoles anyOf={['developer_admin']}>
          <span style={{ fontSize: '15px', color: 'grey', display: 'block' }}>id: {currentXgacDevice._id}</span>
          <span style={{ fontSize: '15px', color: 'grey', display: 'block' }}>legacy id: {currentLegacyDevice.id}</span>
        </HasRoles>
      </div>
      <Autocomplete multiple options={availableGroups} value={selectedGroups}
        defaultValue={currentLegacyDevice.group}
        onChange={updateSelectedGroups}
        autoHighlight={true}
        renderInput={(params) => (
          <MUITextField
            {...params}
            variant="standard"
            label={translate('resources.app-users.text.group_title')}/>
        )}/>
      <Typography variant='body1' sx={{ fontSize: '12px' }}>
        {translate('resources.app-users.text.group_explanation')}
      </Typography>
      <br/>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell>
              <div>{`${translate('resources.app-users.text.last_sync')}:`}</div>
            </TableCell>
            <TableCell><DateShow source={currentLegacyDevice.lastSync}/></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <div>{`${translate('resources.app-users.text.last_config_update')}:`}</div>
            </TableCell>
            <TableCell><DateShow source={currentLegacyDevice.lastConfigUpdate}/></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <div>{`${translate('resources.app-users.text.last_config_compliance')}:`}</div>
            </TableCell>
            <TableCell> <DeviceConfigComplies/></TableCell>
          </TableRow>
        </TableBody>
      </Table>

    </CardContent>
    <Toolbar className="flex-in-between">
      <Button variant='contained' onClick={sendSelectedGroups} disabled={_.isEqual(currentGroups, selectedGroups)}>
        <SaveIcon sx={{ mr: '8px', fontSize: '18px' }}/>{translate('resources.app-users.text.save_groups')}
      </Button>
      <Button onClick={resetGroups} disabled={_.isEqual(currentGroups, selectedGroups)} sx={{ mr: '5px', color: '#d32f2f' }}>
        {translate('resources.app-users.text.reset_groups')}
      </Button>
    </Toolbar>
  </Card>
  <br/>
  <DeviceActions assetId={record.id}/>
  <br/>
  <DeviceInfo legacyDevice={currentLegacyDevice}/>
  </>);

};

const ConfigComparison = () => {

  const translate = useTranslate();
  const [currentDeviceConfig, setCurrentDeviceConfig] = useState();
  const [defaultDeviceConfig, setDefaultDeviceConfig] = useState();
  const [configSwitchChecked, setConfigSwitchChecked] = useState(false);
  const record = useRecordContext();
  const navigate = useNavigate();
  const notify = useNotify();
  useEffect(() => {

    if (!record?._id) return;
    getDeviceConfig(record._id).then((res) => {

      // if the type of device config is default it means that there is no valid device config.
      // Return to the previous page and show an error.
      if (res.deviceConfig.type === 'default') {

        navigate(`/assets/without-app/${record._id}`);

        notify('resources.app-users.text.no_valid_config', { type: 'error' });

      }
      setCurrentDeviceConfig(res.deviceConfig.config);
      setDefaultDeviceConfig(res.defaultConfig);

    }).catch((e) => {

      navigate(`/assets/without-app/${record._id}`);

      notify('resources.app-users.text.no_valid_config', { type: 'error' });

      console.log(e.message);

    });

  }, [navigate, notify, record?._id]);
  if (!record) return null;

  const renderConfigValue = (value: any, edited = false) => {

    // check
    if (typeof value === 'string') {

      // check color
      if (value.match(/(#(?:[0-9a-f]{2}){2,4}|#[0-9a-f]{3}|(?:rgba?|hsla?)\((?:\d+%?(?:deg|rad|grad|turn)?(?:,|\s)+){2,3}[\s/]*[\d.]+%?\))/i) !== null) {

        return <span><div className="badge" style={{ backgroundColor: value }}>&nbsp;</div>&nbsp;{value}</span>;

      }

      return <pre className={(edited === true) ? 'font-weight-bold text-danger' : ''}>{value}</pre>;

    } if (typeof value === 'boolean') {

      return <span className={(edited === true) ? 'font-weight-bold text-danger icon-and-text' : 'icon-and-text'}>
        {value === true
          ? <CheckBoxIcon sx={{ mr: '2px', color: 'green' }}/>
          : <DangerousIcon sx={{ mr: '2px' }}/>} <small>{translate(value === true ? 'general.text.true' : 'general.text.false')}</small>
      </span>;

    } if (typeof value === 'number') {

      return <pre>{value}</pre>;

    }
    if (typeof value === 'object' || Array.isArray(value)) {

      return <ReactJson src={value} collapsed={2} name={null} displayDataTypes={false} style={{ width: 'max-content' }}/>;

    }

    return <pre className={(edited === true) ? 'font-weight-bold text-danger' : ''}>{JSON.stringify(value, null, 2)}</pre>;

  };
  if (!currentDeviceConfig) return null;

  const editedConfig = (): any => {

    const uniqueValues: any = {};

    if (!defaultDeviceConfig || !currentDeviceConfig) return null;

    for (const key in currentDeviceConfig as any) {

      if (!(key in defaultDeviceConfig) || !_.isEqual(currentDeviceConfig[key], defaultDeviceConfig[key])) {

        uniqueValues[key] = currentDeviceConfig[key];

      }

    }

    return uniqueValues;

  };

  const standardConfigWithoutEditedConfig = ():any => {

    const editedDeviceConfig = editedConfig();

    const defaultConfigWithoutEditedConfig = defaultDeviceConfig;

    if (!defaultConfigWithoutEditedConfig) return null;
    for (const key in editedDeviceConfig) {

      if (key in defaultConfigWithoutEditedConfig) {

        delete defaultConfigWithoutEditedConfig[key];

      }

    }
    return defaultConfigWithoutEditedConfig;

  };

  const handleConfigSwitchChange = (event: any, newValue: boolean) => {

    setConfigSwitchChecked(newValue);

  };

  const copyConfig = () => {

    // eslint-disable-next-line no-alert
    const groupName = prompt(translate('resources.app-users.text.download_group'), '');
    let groupConfig = {};
    if (typeof groupName === 'string' && groupName.length > 0) {

      groupConfig = {
        group: groupName,
      };

    }

    const config = editedConfig();

    const output = [];
    for (const key in config) {

      output.push({
        key,
        value: config[key],
        ...groupConfig,
      });

    }

    console.log(JSON.stringify(output));
    notify('resources.app-users.text.view_device_json', { type: 'info' });

  };

  return (
    <Card className="grid-card">
      <CardContent>
        <div className="flex-in-between">
          <Typography variant='h6'>{translate('resources.app-users.text.configuration')}</Typography>
          <div>
            <Typography variant="body1" sx={{ display: 'inline', fontSize: '12px' }}>
              {translate('resources.app-users.text.show_unmodified_keys')}
            </Typography>
            <Switch checked={configSwitchChecked} onChange={handleConfigSwitchChange}/>
          </div>
        </div>
        <Table sx={{ tableLayout: 'fixed' }} size='small' >
          {Object.entries(editedConfig()).sort().map((field) => {

            if (!field || !field[1]) return null;
            return <TableRow>
              <TableCell width={'50%'} sx={{ verticalAlign: 'top', fontSize: '12px' }} className='xg-red'>
                <div className="icon-and-text">
                  <EditIcon/>
                  <span style={{ wordBreak: 'break-all' }}>{field[0]}</span>
                </div>
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top', fontSize: '12px' }} className='xg-red'>
                <div style={{ width: '100%', overflow: 'auto' }}>{renderConfigValue(field[1])}</div>
              </TableCell>
            </TableRow>;

          })
          }
          {configSwitchChecked ? (Object.entries(standardConfigWithoutEditedConfig()).sort().map((field) => {

            if (!field) return null;
            return <TableRow>
              <TableCell width={'50%'} sx={{ verticalAlign: 'top', fontSize: '12px' }}>
                <span style={{ wordBreak: 'break-all' }}>{field[0]}</span>
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top', fontSize: '12px' }}>
                <div style={{ width: '100%', overflow: 'auto' }}>{renderConfigValue(field[1])}</div>
              </TableCell>
            </TableRow>;

          })) : (<></>)}

        </Table>
        <Button onClick={copyConfig}>{translate('resources.app-users.copy_device_json')}</Button>
      </CardContent>
    </Card>
  );

};

const AppModeField = (props: any) => {

  const translate = useTranslate();
  const record = useRecordContext(props);
  const appMode: string = get(record, props.source);
  const value = translate(`general.text.app_mode.${appMode}`);

  // eslint-disable-next-line no-nested-ternary
  const color = appMode === 'on' ? '#008E8E' : appMode === 'off' ? '#D0382A' : '#F5A623';

  return <ChipField {...props} source="value" record={{ value }} sx={{ backgroundColor: color, color: 'white', padding: 0 }}/>;

};

const AvailableField = (props: any) => {

  const record = useRecordContext(props);
  const available = get(record, props.source);
  const translate = useTranslate();
  const value = translate(available ? 'general.text.app_mode.on' : 'general.text.app_mode.off');

  return <ChipField {...props} source="value" record={{ value }} sx={{ backgroundColor: available ? '#1A72BA' : '#D0382A', color: 'white', padding: 0 }}/>;

};

const AppUserPictureField = () => {

  const widthHeight = 30;
  const record = useRecordContext();
  if (!record) return null;
  if (record.properties?.image) {

    return (
      <Avatar alt={record.name} src={record.properties?.image} sx={{ width: widthHeight, height: widthHeight }}/>
    );

  }

  const initials = record.name.match(/\b\w/g);
  const twoInitials = initials?.length > 1 ? initials[0] + initials[initials.length - 1] : initials[0];
  return (
    <Avatar alt={record.name} sx={{ width: widthHeight, height: widthHeight, fontSize: '15px' }}>{twoInitials.toUpperCase()}</Avatar>
  );

};

export const AppUserList = () => {

  return (
    <>
      <PageExplanation text="resources.app-users.text.page_info" />
      <ListLive filters={AssetFilters} exporter={false} debounce={LIST_DEBOUNCE}
        actions={ <AppUserActionButtons/>} title="resources.app-users.text.title"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <Datagrid rowClick="toggleSelection" bulkActionButtons={<AppUserBulkActionButtons/>}>
          <AppUserPictureField/>
          <TextField source="name" label="general.fields.name" />
          <AppModeField source="app.mode" label="resources.assets.fields.my_security"/>
          <AvailableField source="available" label="resources.assets.fields.can_receive"/>
          <ReferenceFieldNullable source="user._id" reference="users" link={false} label="resources.users.fields.username" >
            <TextFieldNullable source="username" privacy/>
          </ReferenceFieldNullable>
          <ContactField label="general.text.contact_info"/>
          <ReferenceArrayFieldNullable source="assetGroups" reference="asset-groups" label="resources.asset-groups.name"/>
          <DateFieldNullable source="lastObservationAt" showTime={true} label="resources.assets.fields.last_active_at" />
          <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
          <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
          <HasRoles anyOf={['admin', 'customer_admin']}>
            <EditButton/>
          </HasRoles>
        </Datagrid>
      </ListLive>
    </>);

};

const AppUserToolbar = () => {

  const translate = useTranslate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteAllDevices, setDeleteAllDevices] = useState(false);

  const DeleteAppUserButton = () => {

    const notify = useNotify();
    const redirect = useRedirect();

    const id = useGetRecordId();
    const [deleteOne, { isLoading, error }] = useDelete(
      'app-users',
      { id, meta: { deleteAllDevices } },
    );

    const deleteAppUsers = () => {

      deleteOne();
      setDialogOpen(false);

      if (!isLoading && !error) {

        notify('resources.app-users.text.delete_success_single', { type: 'info' });
        redirect('/app-users');

      }

    };
    if (error) {

      notify('resources.app-users.text.delete_failed_single', { type: 'warning' });

    }

    return <Button onClick={deleteAppUsers} color="warning" startIcon={<DeleteIcon/>}>{translate('ra.action.delete')}</Button>;

  };

  return (
    <>
      <Toolbar>
        <Button onClick={() => setDialogOpen(true)} color="warning" startIcon={<DeleteIcon/>}>{translate('ra.action.delete')}</Button>
        <SaveButton/>
      </Toolbar>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          {translate('resources.app-users.text.delete_title')}
        </DialogTitle>
        <DialogContent>
          <FormControlLabel control={
            <Checkbox checked={deleteAllDevices} onChange={() => setDeleteAllDevices(!deleteAllDevices)}/>
          } label={translate('resources.app-users.text.delete_all_devices')}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} autoFocus>
            <>{translate('ra.action.delete')}</>
          </Button>
          <DeleteAppUserButton/>
        </DialogActions>
      </Dialog>
    </>
  );

};

export const AppUserCreate = () => {

  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const [code, setCode] = useState<string | null>(null);
  const assetCreateOnSuccess = (data: any) => {

    if (data.error) {

      notify(data.message, { type: 'error' });
      return;

    }

    notify(translate('resources.app-users.text.created', { username: data.user?.username }), { type: 'success', autoHideDuration: 10000 });
    redirect('edit', 'app-users', data._id);

  };
  return (
    <Create
      title="resources.app-users.text.title"
      transform={appUserCreateTransformer}
      actions={false}
      mutationOptions={{ meta: { needsUserCreation: true, code }, onSuccess: assetCreateOnSuccess }}
    >
      <SimpleForm toolbar={<CreateToolbarRight narrow/>} sx={{ maxWidth: '1000px' }}>
        <RegistrationCodePicker setCode={setCode}/>
        <Typography variant="h6" gutterBottom>
          {translate('general.fields.name')}
        </Typography>
        <TextInput
          fullWidth
          source="name"
          label="general.fields.name"
          validate={required()}
        />
        <Typography variant="h6" gutterBottom>
          {translate('resources.assets.text.properties')}
        </Typography>
        <PhoneNumberInput
          source="properties.phoneNumber"
          label="resources.assets.fields.phoneNumber"
          colorVariant="normal"
          fullWidth
        />
        <TextInput
          source="properties.email"
          label="resources.assets.fields.email"
          validate={[email(), required()]}
          fullWidth
        />
        <HasRoles anyOf={['admin', 'customer_admin_external_id']}>
          <TextInput
            source="externalId"
            fullWidth
            label="resources.assets.fields.externalId"
            shouldUnregister
          />
        </HasRoles>
        <UsernameInput color="primary"/>
        <Typography variant="h6" gutterBottom marginTop={'10px'}>
          {translate('resources.qr-templates.text.options')}
        </Typography>
        <div style={{ width: '100%' }}>
          <FormDataConsumer>
            {({ formData }) => {

              return (
                <BooleanCheckBoxInput
                  source="available"
                  inputLabel="resources.users.text.available"
                  helperText="resources.app-users.text.available_helper"
                  disabled={!formData.properties?.phoneNumber || formData.properties?.phoneNumber === '+31'}
                  disabledText={translate('resources.app-users.text.fill_phonenumber')}
                />
              );

            }}
          </FormDataConsumer>
        </div>
      </SimpleForm>
    </Create>
  );

};

const SimpleAppUserEditFields = () => {

  const record = useRecordContext();
  const translate = useTranslate();
  if (!record) return null;
  return (
    <>
      <h4 style={{ marginBottom: '10px' }}>"{record.name}" {translate('general.text.settings')}</h4>
      <hr/>
      <Typography variant="h6" gutterBottom marginTop={'10px'}>
        {translate('resources.assets.text.properties')}
      </Typography>
      <Grid container spacing={3}>
        <Grid md={6}>
          <TextInput
            source="name"
            label="general.fields.name"
            fullWidth
            validate={required()}
          />
          <TextInput
            source="properties.email"
            label="resources.assets.fields.email"
            fullWidth
            validate={email()}
          />
          <HasRoles anyOf={['admin', 'customer_admin_external_id']}>
            <TextInput
              source="externalId"
              fullWidth
              label="resources.assets.fields.externalId"
              shouldUnregister
            />
          </HasRoles>
        </Grid>
        <Grid md={6}>

          <ReferenceOneInput reference="users" target="_id" source="userId" fullWidth>
            <TextInput source="username" fullWidth label="resources.users.fields.username" disabled/>
          </ReferenceOneInput>
          <PhoneNumberInput source="properties.phoneNumber" fullWidth colorVariant="normal"/>
        </Grid>
      </Grid>
      <hr/>
      <Typography variant="h6" gutterBottom marginTop={'10px'}>
        {translate('resources.qr-templates.text.options')}
      </Typography>
      <div style={{ width: '100%' }}>
        <FormDataConsumer>
          {({ formData }) => {

            return (
              <BooleanCheckBoxInput
                source="available"
                inputLabel="resources.users.text.available"
                helperText="resources.app-users.text.available_helper"
                disabled={!formData.properties?.phoneNumber || formData.properties?.phoneNumber === '+31'}
                disabledText={translate('resources.app-users.text.fill_phonenumber')}
              />
            );

          }}
        </FormDataConsumer>
      </div>
      <hr/>
      <Typography variant="h6" gutterBottom>
        {translate('menu.items.groups')}
      </Typography>
      <ReferenceArrayInput source="assetGroups" reference="asset-groups" label="menu.items.groups">
        <AutocompleteArrayInput label="menu.items.groups"/>
      </ReferenceArrayInput>
      <HasRoles anyOf={['admin', 'customer_admin_app_user_history']}>
        <hr/>
        <Typography variant="h6" gutterBottom>
          {translate('resources.assets.text.download_history_button')}
        </Typography>
        <DownloadAssetHistoryButton/>
      </HasRoles>
    </>
  );

};

const smsTransform = (data: any) => {

  if (data.assetGroups) {

    data.assetGroups = data.assetGroups.map((group: any) => {

      return {
        _id: group,
        _ref: 'AssetGroup',
      };

    });

  }
  if (data.properties?.phoneNumber) {

    data.properties.smsNumber = data.properties.phoneNumber;

  }

  return data;

};
const CustomerAdminAppUserEdit = () => {

  return (
    <EditWithSuccess title="resources.app-users.text.title" actions={false} transform={smsTransform}>
      <SimpleForm toolbar={<CustomToolbar narrow/>} sx={{ maxWidth: '1000px' }}>
        <SimpleAppUserEditFields/>
      </SimpleForm>
    </EditWithSuccess>
  );

};

const LinkToUserField = (props: any) => {

  const record = useRecordContext(props);
  const translate = useTranslate();

  if (!record || !record.user) {

    return null;

  }
  return (
    <Link to={`/users/without-app/${record.user?._id}`} sx={{
      marginTop: '5px',
    }}>
      {translate('resources.app-users.text.go_to_connected_users')}
    </Link>
  );

};
export const AppUserEdit = () => {

  const translate = useTranslate();
  const [map, setMap] = useState<Map | null>(null);
  const refresh = useRefresh();
  const roles = authProvider.getRoles();

  useEffect(() => {

    map?.scrollWheelZoom.disable();
    map?.on('focus', () => {

      map.scrollWheelZoom.enable();

    });
    map?.on('blur', () => {

      map.scrollWheelZoom.disable();

    });

  }, [map]);
  const notify = useNotify();
  const OnSuccess = () => {

    notify('resources.app-users.text.updated');
    refresh();

  };

  if (!roles.includes('admin') && roles.includes('customer_admin')) {

    return <CustomerAdminAppUserEdit/>;

  }

  // actions are set to false here because the "clone" button should not be visible while editing app-users
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable} />
      <EditWithSuccess actions={false} transform={transform} component="div" className='edit-base'
        mutationOptions={{ onSuccess: OnSuccess }} mutationMode="pessimistic" title="resources.app-users.text.title"
      >
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Card >
              <SimpleForm toolbar={<AppUserToolbar/>}>
                <HasRoles anyOf={['admin']}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                    <DownloadAssetHistoryButton/>
                    <LinkToUserField/>
                  </div>
                </HasRoles>
                <Grid container spacing={2}>
                  <Grid xs={6} md={6}>
                    <Typography variant="h6" gutterBottom>
                      {translate('resources.assets.text.general')}
                    </Typography>
                    <TextInput source="name" sx={{ display: 'block' }} fullWidth label="general.fields.name"/>
                    <ReferenceOneInput reference="users" target="_id" label="username" source="userId" fullWidth>
                      <TextInput source="username" fullWidth label="resources.users.fields.username"/>
                    </ReferenceOneInput>
                    <TextInput source="externalId" label="resources.assets.fields.externalId" sx={{ display: 'block' }} fullWidth/>
                    <TextInput source="description" label="resources.assets.fields.description" fullWidth/>
                    <DateTimeInput source="lastObservationAt" disabled fullWidth label="resources.assets.fields.lastObservationAt"
                      sx={{ userSelect: 'text' }}
                    />
                    <HasRoles anyOf={['developer_admin']}>
                      <TextInput source="user._id" label="User ID" disabled fullWidth/>
                      <TextInput source="_id" label="Asset ID" disabled fullWidth/>
                    </HasRoles>
                    <Typography variant="h6" gutterBottom>
                      {translate('resources.assets.text.app')}
                    </Typography>
                    <Typography variant='body1' sx={{ fontSize: '12px' }}>
                      {translate('resources.assets.text.app_mode_not_editable')}
                    </Typography>
                    <SelectInput source="app.mode" choices={appModeOptions} defaultValue={'off'} validate={required()} disabled fullWidth/>
                    <Typography variant='body1' sx={{ fontSize: '12px' }}>
                      {translate('resources.assets.text.app_available_explanation')}
                    </Typography>
                    <BooleanInput source="available" label="resources.assets.fields.available"/>
                    <Typography variant="h6" gutterBottom>
                      {translate('menu.items.groups')}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      {translate('resources.assets.text.response')}
                    </Typography>
                    <ArrayInput source="staticResponders" label="resources.assets.fields.staticResponders.name">
                      <SimpleFormIterator disableReordering>
                        <ReferenceInput name="static_responders" source="staticResponder" reference="static-responders">
                          <AutocompleteInput validate={required()} autoHighlight={true} label="resources.assets.fields.staticResponders.staticResponder"/>
                        </ReferenceInput>
                        <NumberInput source="priority" label="resources.assets.fields.staticResponders.priority"/>
                      </SimpleFormIterator>
                    </ArrayInput>
                    <BooleanInput source="invisibleResponder" label="resources.assets.fields.invisibleResponder"/>
                    <BooleanInput name="allowContactOnOwnAlarms" source="allowContactOnOwnAlarms"
                      label="resources.assets.fields.allowContactOnOwnAlarms"
                      sx={{ width: '200%' }}
                    />

                  </Grid>
                  <Grid xs={6} md={6}>
                    <Typography variant="h6" gutterBottom>
                      {translate('resources.assets.text.properties')}
                    </Typography>
                    <TextInput source="properties.phoneNumber" label="resources.assets.fields.phoneNumber" sx={{ display: 'block' }} fullWidth/>
                    <TextInput source="properties.smsNumber" label="resources.assets.fields.smsNumber" fullWidth/>
                    <TextInput source="properties.email" label="resources.assets.fields.email" fullWidth/>
                    <ImageInput source="properties.image" label="resources.assets.fields.image">
                      <ImageField source="src" title="title"/>
                    </ImageInput>

                    <Typography variant="h6" gutterBottom>
                      {translate('menu.items.groups')}
                    </Typography>
                    <ReferenceArrayInput source="assetGroups" reference="asset-groups" label="menu.items.groups" fullWidth>
                      <AutocompleteArrayInput label="menu.items.groups" fullWidth/>
                    </ReferenceArrayInput>
                    <>
                      <Typography variant="h6" gutterBottom>
                        {translate('resources.users.fields.group_restrictions')}
                      </Typography>
                      <ReferenceOneInput reference='customer-user-relations'
                        target='user._id'
                        source='userId'
                        label=""
                        fullWidth
                      >
                        <div className="d-none">
                          <ReferenceInput source="customerId" reference="customers" label="resources.customers.name">
                            <SelectInput disabled/>
                          </ReferenceInput>
                        </div>
                        <ReferenceArrayInput source="assetGroupRestrictions" reference="asset-groups" fullWidth>
                          <AutocompleteArrayInput label="resources.asset-groups.name" emptyValue={[]} fullWidth/>
                        </ReferenceArrayInput>
                      </ReferenceOneInput>
                    </>
                    <BooleanInput source="position.properties.provider"
                      format={(v) => (v === 'static')}
                      parse={(v) => (v ? 'static' : 'device')}
                      label="resources.assets.fields.position.provider"
                      sx={{ width: '100%' }}
                    />
                    <FormDataConsumer>
                      {({ formData }) => (formData.position?.properties?.provider === 'static')
                        && <PositionForm source="position"/>
                      }
                    </FormDataConsumer>
                  </Grid>
                  <Grid xs={6} md={6}>
                    <Typography variant="h6" gutterBottom>
                      {translate('general.fields.position.name')}
                    </Typography>
                    <ReferenceArrayInput source="insideZones" reference="zones">
                      <AutocompleteArrayInput fullWidth disabled sx={{
                        '& .MuiInputBase-root.Mui-disabled': {
                          backgroundColor: '#f0f0f0',
                        },
                      }} autoHighlight={true}
                      label="resources.assets.fields.insideZones"
                      />
                    </ReferenceArrayInput>
                  </Grid>
                  <Grid xs={12} md={12}>
                    <FormDataConsumer>
                      {({ formData }) => (
                        formData.position?.properties?.address?.formattedAddress
                          && formData.position?.coordinates
                          && formData.position?.coordinates[0]
                          && formData.position?.coordinates[1]
                          && formData.position?.coordinates[0] > -90 && formData.position?.coordinates[0] < 90
                          && formData.position?.coordinates[1] > -90 && formData.position?.coordinates[1] < 90
                      )
                        && (
                          <>
                            <DateTimeInput source="position.properties.dateTime" disabled label="general.fields.position.properties.dateTime"
                              sx={{ userSelect: 'text' }}
                            />
                            <MapContainer
                              ref={(ref: any) => setMap(ref)}
                              center={[formData.position.coordinates[1], formData.position.coordinates[0]]}
                              zoom={17}
                              minZoom={4}
                              zoomControl={false}
                              style={{ height: 400, width: '100%', marginTop: 8 }}
                              maxZoom={19}
                            >
                              <TileLayer
                                maxZoom={19}
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              />
                              <Popup position={[formData.position.coordinates[1], formData.position.coordinates[0]]}
                                closeButton={false}
                                closeOnClick={false}
                                autoClose={false}
                                closeOnEscapeKey={false}>
                                {formData.position.properties.address.formattedAddress}
                                <br/>
                                <a target="_blank" href={`https://www.google.com/maps/place/${formData.position.coordinates[1]},${formData.position.coordinates[0]}/@${formData.position.coordinates[1]},${formData.position.coordinates[0]},14z/`} rel="noreferrer">
                                  {formData.position.coordinates[1]},{formData.position.coordinates[0]}
                                </a>
                              </Popup>
                            </MapContainer>
                          </>
                        )}
                    </FormDataConsumer>
                  </Grid>
                </Grid>
              </SimpleForm>
            </Card>
            <br/>
            <AppDeviceConfig/>
          </Grid>
          <Grid xs={6}>
            <ConfigComparison/>
          </Grid>
        </Grid>
      </EditWithSuccess>
    </>
  );

};
