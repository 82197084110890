import {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import L, { Map } from 'leaflet';
import { useTranslate } from 'ra-core';
import * as React from 'react';
import MapIcon from '@mui/icons-material/Map';
import SatelliteIcon from '@mui/icons-material/Satellite';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useOutsideAlerter } from '../../../utils/useOutsideAlerter';

export const CustomLayerControl = (props: {
  map: Map | null;
  toggleVisible?: boolean;
  attribution?: string;
  setUpperTileLayer?: Dispatch<SetStateAction<string>>;
}) => {

  const [mapTileLayer, setMapTileLayer] = useState<string>(localStorage.getItem('mapTileLayer') || 'Default');

  const translate = useTranslate();

  const defaultTileLayer = L.tileLayer(
    'https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=2ODowAtqXu3iAzkHTXEX4FePUx1JEIAKCSgoOXyLZIf1RBMvfnE5aHOOvJgVQUib',
    {
      attribution: props.attribution || '',
      maxNativeZoom: 22,
      maxZoom: 24,
    },
  );
  const satelliteTileLayer = L.tileLayer(
    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    {
      attribution: props.attribution || '',
      maxNativeZoom: 21,
      maxZoom: 24,
    },
  );

  useEffect(() => {

    if (!props.map) return;
    props.map.eachLayer((layer) => {

      if (layer instanceof L.TileLayer) {

        props.map?.removeLayer(layer);

      }

    });
    if (mapTileLayer === 'Default') {

      if (!props.map.hasLayer(defaultTileLayer)) {

        defaultTileLayer.addTo(props.map);

      }

    } else if (!props.map.hasLayer(satelliteTileLayer)) {

      satelliteTileLayer.addTo(props.map);

    }

  }, [mapTileLayer, props.map]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleLayer = (e: React.MouseEvent<HTMLElement>) => {

    e.preventDefault();
    e.stopPropagation();
    setMapTileLayer(mapTileLayer === 'Default' ? 'Satellite' : 'Default');
    if (props.setUpperTileLayer) {

      props.setUpperTileLayer(mapTileLayer === 'Default' ? 'Satellite' : 'Default');

    }
    localStorage.setItem('mapTileLayer', mapTileLayer === 'Default' ? 'Satellite' : 'Default');

  };

  if (!props.map) return null;

  return (

    <button onClick={toggleLayer} className="map-control-button" style={
      props.toggleVisible ? {} : { display: 'none' }
    }>
      {mapTileLayer === 'Default' ? <MapIcon /> : <SatelliteIcon />}
      <span>{translate(mapTileLayer === 'Default' ? 'resources.buttons.text.map_default' : 'resources.buttons.text.map_satellite')}</span>
    </button>
  );

};

export const CenterControl = (props: { onClick: () => void }) => {

  const translate = useTranslate();

  return (
    <button onClick={props.onClick} className="map-control-button">
      <CenterFocusStrongIcon />
      <span>{translate('resources.buttons.text.map_center')}</span>
    </button>
  );

};

export const BooleanFilter = (props: { value: boolean; onClick: () => void; label: string }) => {

  const translate = useTranslate();
  return (
    <div className="map-boolean-filter" onClick={props.onClick}>
      <input type="checkbox" checked={props.value} readOnly/>
      <span style={props.value ? {
        fontWeight: 'bolder',
      } : {}}>{translate(props.label)}</span>
    </div>
  );

};

export const FloorFilter = (props: { floors: number[]; selectedFloors: number[]; onChange: (floors: number[]) => void }) => {

  const [open, setOpen] = useState(false);
  const filterRef = useRef(null);
  const translate = useTranslate();

  useOutsideAlerter(filterRef, () => setOpen(false));

  return (
    <div className="floor-filter" ref={filterRef}>
      <div className="floor-filter-list" style={open ? {} : { display: 'none' }}>
        <ul>
          {props.floors.map((floor) => (
            <li key={floor} onClick={() => {

              if (props.selectedFloors.includes(floor)) {

                props.onChange(props.selectedFloors.filter((f) => f !== floor));

              } else {

                props.onChange([...props.selectedFloors, floor]);

              }

            }} className={props.selectedFloors.includes(floor) ? 'selected' : ''}>
              <input type="checkbox" checked={props.selectedFloors.includes(floor)}/>
              <span>{translate('general.text.map_floors')} {floor}</span>
            </li>
          ))}
        </ul>
      </div>
      <button
        onClick={() => setOpen(!open)}
        className={props.selectedFloors.length > 0 ? 'selected-button' : ''}
        disabled={props.floors.length < 2}
      >
        <span>{translate(props.selectedFloors.length > 0 && props.selectedFloors.length !== props.floors.length
          ? 'resources.buttons.text.floors_chosen'
          : 'resources.buttons.text.all_floors')}
        </span>
        {open ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/>}
      </button>
    </div>
  );

};
