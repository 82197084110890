import {
  MapContainer,
  ScaleControl,
} from 'react-leaflet';
import L, {
  LatLng, LatLngBoundsExpression, Layer, Point,
} from 'leaflet';
import * as React from 'react';
import {
  Identifier,
  useNotify, useRecordContext,
} from 'react-admin';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import uniqolor from 'uniqolor';
import _ from 'lodash';
import * as turf from '@turf/turf';
import { Feature } from '@turf/turf';
import { getCurrentCustomer } from '../../lib/currentCustomer';
import { ZoneWithBeacons } from '../../lib/constants/customTypes';
import {
  BooleanFilter,
  CenterControl,
  CustomLayerControl,
  FloorFilter,
} from '../../apps/bhvk/components/LeafletControls';
import { wait } from '../../lib/websockets/webSocketHelpers';

export const polygonZoneEditOptions = {
  allowSelfIntersection: false,
  limitMarkersToCount: 10,
  removeLayerBelowMinVertexCount: false,
};

export const markerZoneEditOptions = {
  draggable: true,
  preventMarkerRemoval: true,
  snappable: false,
  allowRemoval: false,
};

const onePolygonZoneEditOptions = {
  allowSelfIntersection: false,
  limitMarkersToCount: 10,
};

export const uniqColorOptionsForZones = {
  saturation: [100, 100],
  lightness: [10, 80],
};

export const ZoneMap = (props: any) => {

  const record = useRecordContext();
  const notify = useNotify();
  const map = props.map;

  map?.pm.addControls({
    position: 'topleft',
    drawText: false,
    drawCircle: false,
    // disable some buttons to make it easier and limit options
    drawCircleMarker: false,
    drawPolyline: false,
    drawMarker: false,
    drawRectangle: false,
    editMode: false,
    cutPolygon: false,
  });

  map?.pm.enableGlobalEditMode(onePolygonZoneEditOptions);

  map?.on('pm:globalremovalmodetoggled', (e: any) => {

    // when enabled, we don't care
    if (e.enabled === true) {

      return;

    }

    // when disabled we check if the layer was actually removed, if not enable the edit mode again
    const layers = map.pm.getGeomanLayers();
    if (layers.length === 1) {

      map.pm.enableGlobalEditMode(onePolygonZoneEditOptions);

    }

  });
  // listen for drag mode toggle
  map?.on('pm:globaldragmodetoggled', (e: any) => {

    // we dont care about enabling
    if (e.enabled === true) {

      return;

    }

    // on disable we start edit mode again
    map.pm.enableGlobalEditMode(onePolygonZoneEditOptions);

  });

  map?.on('pm:globaldrawmodetoggled', (e: any) => {

    // when draw mode is disabled and edit mode is disabled as well, enable it again
    if (e.enabled === false) {

      if (!map.pm.globalEditModeEnabled()) {

        map.pm.enableGlobalEditMode(onePolygonZoneEditOptions);

      }

      return;

    }

    // check if we have already have 1 zone drawn, if so, block the addition of more
    const layers = map.pm.getGeomanLayers();
    if (layers.length !== 0) {

      notify('resources.zones.text.one_figure_per_zone', { type: 'warning' });

      // disable edit mode first to stop draw mode, wait 1 tick and enable again
      // bit hacky, but does the trick
      map.pm.disableGlobalEditMode();
      setTimeout(() => {

        map.pm.enableGlobalEditMode(onePolygonZoneEditOptions);

      }, 0);

    }

  });

  const temporaryLayers = map?.pm.getGeomanLayers();
  if (record?.location && map && temporaryLayers?.length === 0) {

    const zoneToAdd = {
      type: 'Feature',
      id: record.id,
      properties: {
        name: record.name,
      },
      geometry: record.location,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore geoman typings are not correct
    L.geoJson(zoneToAdd).addTo(map);

    const allCoordinates = record.location.coordinates[0].map((coordinates: any[]) => {

      return [coordinates[1], coordinates[0]];

    });

    map?.flyToBounds(allCoordinates);

  }

  return (
    <>
      <MapContainer
        ref={(ref: any) => props.setMap(ref)}
        center={[52.1009166, 5.6462914]}
        zoom={12}
        minZoom={2}
        maxZoom={20}
        style={{
          height: '80vh', width: '80%',
        }}
      >
        <div className="leaflet-control-container-custom">
          <CustomLayerControl
            map={map}
            toggleVisible={true}
          />
        </div>
        <ScaleControl position="bottomright" imperial={false}/>
      </MapContainer>
    </>
  );

};

export const compareZoneArrays = (newItems: ZoneWithBeacons[], oldItems: ZoneWithBeacons[]): [added: ZoneWithBeacons[], removed: ZoneWithBeacons[]] => {

  const diff = (left: ZoneWithBeacons[], right: ZoneWithBeacons[]) => left.filter((x) => {

    return right.findIndex((y) => x._id === y._id) === -1;

  });

  return [diff(newItems, oldItems), diff(oldItems, newItems)];

};

export const MultipleZonesMap = (
  props: {
    zones: Array<ZoneWithBeacons>;
    handleClickOnZone: any;
    map: any;
    setMap: Dispatch<SetStateAction<any>>;
    createMode: false | 'polygon' | 'marker';
    expandedId: Identifier;
    formEdited: boolean;
    setFormEdited: Dispatch<SetStateAction<boolean>>;
    possibleFloors: number[];
    selectedFloors: number[];
    setSelectedFloors: Dispatch<SetStateAction<number[]>>;
    filterInBounds: boolean;
    setFilterInBounds: Dispatch<SetStateAction<any>>;
  },
) => {

  const map = props.map;
  const setMap = props.setMap;

  const [innerZones, setInnerZones] = useState<Array<ZoneWithBeacons>>([]);
  const [zoomed, setZoomed] = useState<boolean>(false);
  const [savedCustomer, setSavedCustomer] = useState<string>('');

  const currentCustomer = getCurrentCustomer()?.value;

  useEffect(() => {

    if (currentCustomer && currentCustomer !== savedCustomer) {

      setSavedCustomer(currentCustomer);
      setZoomed(false);

    }

  }, [currentCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (_.isEqual(props.zones, innerZones)) {

      return;

    }
    let allZoneArray = [...props.zones];
    const currentLayers = map?.pm.getGeomanLayers();

    const [added, removed] = compareZoneArrays(props.zones, innerZones);
    setInnerZones(props.zones);

    added.forEach((addedZone) => {

      const color = uniqolor(
        addedZone._id,
        uniqColorOptionsForZones,
      );

      let layer;
      if (addedZone.zoneSize > 5) {

        const zoneStyle = {
          color: color.color,
          fillColor: color.color,
          fillOpacity: 0.3,
          weight: 2,
        };

        const zoneToAdd = {
          type: 'Feature',
          id: addedZone._id,
          properties: {
            name: addedZone.name,
          },
          geometry: addedZone.location,
          key: addedZone._id,
          updatedAt: addedZone.updatedAt,
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore geoman typings are not correct
        layer = L.geoJson(zoneToAdd, { style: zoneStyle });
        layer.on('pm:markerdragend', () => {

          props.setFormEdited(true);

        });

      } else {

        const center = turf.center(addedZone.location).geometry.coordinates;
        const zoneToAdd = {
          type: 'Feature',
          id: addedZone._id,
          properties: {
            name: addedZone.name,
          },
          geometry: {
            type: 'Point',
            coordinates: center,
          },
          updatedAt: addedZone.updatedAt,

        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore geoman typings are not correct
        layer = L.geoJson(zoneToAdd, {
          pointToLayer(geoJsonPoint: Feature<Point>, latlng: LatLng): Layer {

            return L.marker(latlng, {
              draggable: false,
              icon: L.divIcon({
                className: addedZone._id,
                iconAnchor: [11, 36],
                popupAnchor: [0, -24],
                html: `<div class="pin pincolor-${color.color.replace('#', '')}"></div><style>
  .pin.pincolor-${color.color.replace('#', '')} {
    border-color: ${color.color};
  }
  .pin.pincolor-${color.color.replace('#', '')}::after { border-top-color: ${color.color} }
</style>`,
              }),
            });

          },
        });

        layer.on('pm:edit', () => {

          props.setFormEdited(true);

        });

      }
      if (map) {

        layer?.addTo(map);

      }
      allZoneArray = allZoneArray.filter((zone) => zone._id !== addedZone._id);

    });

    removed.forEach((removedZone) => {

      currentLayers?.forEach((layer: any) => {

        if (layer.feature?.id === removedZone._id) {

          map?.removeLayer(layer);

        }

      });
      allZoneArray = allZoneArray.filter((zone) => zone._id !== removedZone._id);

    });

    allZoneArray.forEach((zone) => {

      const innerLayer = map?.pm.getGeomanLayers().find((a: any) => {

        return a.toGeoJSON().id === zone._id;

      });
      if (!innerLayer) {

        return;

      }
      const geoJsonLayer = innerLayer.toGeoJSON();

      if (geoJsonLayer?.id === zone._id && geoJsonLayer.updatedAt !== zone.updatedAt.toString()) {

        if (innerLayer instanceof L.Polygon) {

          innerLayer.setLatLngs(zone?.location.coordinates[0].map((coordinates: any[]) => [coordinates[1], coordinates[0]]));

        } else if (innerLayer instanceof L.Marker) {

          const center = turf.center(zone.location).geometry.coordinates;
          innerLayer.setLatLng([center[1], center[0]]);

        }

      }

    });

    const mapBounds = map?.getBounds();
    const layersInMapBounds = map?.pm.getGeomanLayers().filter((layer: any) => {

      if (layer instanceof L.Polygon) {

        return mapBounds?.contains(layer.getBounds());

      } if (layer instanceof L.Marker) {

        return mapBounds?.contains(layer.getLatLng());

      }
      return false;

    });
    if (!props.filterInBounds && layersInMapBounds?.length === 0 && allZoneArray.length > 0) {

      setZoomed(false);

    }

  }, [props.zones]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    map?.eachLayer((layer: any) => {

      if (layer instanceof L.Polygon || layer instanceof L.Marker) {

        if (layer.toGeoJSON().id) {

          layer.off('click');
          layer.on('click', () => {

            props.handleClickOnZone(layer.toGeoJSON().id);

          });

        }

      }

    });

  }, [props.createMode, innerZones, props.formEdited, props.handleClickOnZone]); // eslint-disable-line react-hooks/exhaustive-deps

  // when props.createMode is on, make it possible to draw a new zone
  useEffect(() => {

    if (!props.createMode) {

      map?.pm?.disableDraw();
      const layer = map?.pm?.getGeomanLayers()?.find((innerLayer: any) => innerLayer.myId === -1);
      if (layer) {

        // delete layer from map
        map?.removeLayer(layer);

      }

      return;

    }

    if (props.createMode === 'marker') {

      // allow one marker to be drawn
      map?.pm?.enableDraw('Marker', {
        snappable: false,
        markerEditable: false,
        finishOn: 'click',
        markerStyle: {
          draggable: false,
          icon: L.divIcon({
            className: 'marker',
            iconAnchor: [11, 36],
            popupAnchor: [0, -24],
            html: `<div class="pin pincolor-1972b9"></div><style>
  .pin.pincolor-1972b9 {
    border-color: #1972b9;
  }
  .pin.pincolor-1972b9::after { border-top-color: #1972b9 }
</style>`,
          }),
        },
      });

      map?.on('pm:create', (e: any) => {

        map?.pm.disableDraw();
        e.layer.myId = -1;
        e.layer.pm?.enable(markerZoneEditOptions);

      });

    } else {

      map?.pm.enableDraw('Polygon', {
        snappable: true,
        snapDistance: 20,
        allowSelfIntersection: false,
        limitMarkersToCount: 10,
      });

      map?.on('pm:create', (e: any) => {

        e.layer.myId = -1;
        e.layer.pm?.enable(polygonZoneEditOptions);

      });

    }

  }, [props.createMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (props.zones?.length === 0) {

      return;

    }

    if (!zoomed) {

      props.setFilterInBounds(false);

      wait(10).then(() => {

        const allCoordinates = props.zones?.map((zone) => {

          return zone.location?.coordinates[0].map((coordinates: any[]) => {

            return [coordinates[1], coordinates[0]];

          });

        }).flat();

        map?.fitBounds(allCoordinates as LatLngBoundsExpression);
        setZoomed(true);

      });

    }

  }, [props.zones, zoomed]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MapContainer
      ref={(ref: any) => setMap(ref)}
      center={[52.1009166, 5.6462914]}
      zoom={17}
      minZoom={4}
      zoomControl={false}
      style={{ height: '100%' }}
    >
      <ScaleControl position="bottomright" imperial={false}/>
      <div>
        <div className="leaflet-control-container-custom">
          <CustomLayerControl
            map={map}
            toggleVisible={true}
          />
          <CenterControl onClick={() => {

            setZoomed(false);

          }}/>
        </div>
        <div className="second-filter-div">
          <FloorFilter floors={props.possibleFloors} selectedFloors={props.selectedFloors} onChange={props.setSelectedFloors}/>
          <BooleanFilter value={props.filterInBounds} onClick={() => {

            props.setFilterInBounds(!props.filterInBounds);

          }} label="resources.zones.text.filter_in_bounds"/>
        </div>
      </div>
    </MapContainer>
  );

};
