import {
  ArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  Datagrid,
  EditButton,
  Identifier,
  List,
  Loading,
  ReferenceArrayInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  useEditContext,
  useGetRecordId,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
  Button,
  RaRecord,
  ListActions,
  useSimpleFormIteratorItem,
} from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Switch as MuiSwitch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField as MuiTextField,
  Typography, Tooltip,
} from '@mui/material';
import { useRecordContext } from 'ra-core';
import _, { get } from 'lodash';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { PDFDownloadLink } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import Papa from 'papaparse';
import * as jschardet from 'jschardet';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { qrCodeDataProvider } from '../../../dataProviders/qrCodeDataProvider';
import DateFieldNullable from '../../../components/fields/DateFieldNullable';
import { httpClient } from '../../../utils/httpClient';
import { DEVICE_REGISTRATION_API_URL } from '../../../config';
import Poster from '../../../components/Poster';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { UploadedCSV } from '../../../lib/constants/customTypes';
import { downloadEmptyCSV, RegisterUsers, validateUploadedCsv } from '../../../utils/qrCsvService';
import authProvider from '../../../utils/authProvider';
import { PageExplanation } from '../../../components/PageExplanation';
import { wait } from '../../../lib/websockets/webSocketHelpers';
import SearchFields from '../components/fields/Filters';
import { AppUserCreateButton } from '../components/buttons/AppUserCreateButton';
import ReferenceArrayFieldNullable from '../../../components/fields/ReferenceArrayFieldNullable';

const deviceRegistrationApiUrl = DEVICE_REGISTRATION_API_URL;

const QrCodeDownload = (props: any) => {

  const notify = useNotify();
  const [downloadPoster, setDownloadPoster] = useState<any>(false);
  const [renderPosterQr, setRenderPosterQr] = useState<any>(false);
  const [previewQr, setPreviewQr] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const record = useRecordContext(props);

  if (!record || !record.code) {

    return (<>
      <Tooltip arrow placement="top" title="Contact X-Guard">
        <span>
          <Button size={'small'} disabled variant={'contained'} label="Download"/>
        </span>
      </Tooltip>
      <Tooltip arrow placement="top" title="Contact X-Guard">
        <span>
          <Button size={'small'} disabled variant={'contained'} sx={{ marginLeft: '20px' }} label="resources.qr-templates.text.preview"/>
        </span>
      </Tooltip>
      <Tooltip arrow placement="top" title="Contact X-Guard">
        <span>
          <AppUserCreateButton
            size={'small'}
            variant="contained"
            sx={{ marginLeft: '20px' }}
            label="resources.users.text.add"
            disabled
          />
        </span>
      </Tooltip>
    </>);

  }
  const forceDownloadPoster = (url: string, name: string) => {

    // create element
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloadPoster(false);

  };

  const closePreview = () => {

    setPreviewOpen(false);
    setDownloadPoster(false);
    setRenderPosterQr(false);

  };

  const generateQr = (preview: boolean) => {

    if (!preview) {

      notify('resources.qr-templates.text.generating_qr', { type: 'info' });

    }
    // reset first
    setDownloadPoster(false);
    setRenderPosterQr(record.code);

    setTimeout(() => {

      setDownloadPoster({
        code: { ...record.code, variables: [] },
        templateName: record.name,
        qrCode: (document.getElementById('qrCodeRenderPoster') as HTMLCanvasElement).toDataURL(),
      });

      setPreviewQr(preview);
      setPreviewOpen(preview);

    });

  };

  return (
    <>
      <Button onClick={() => generateQr(false)} size={'small'} variant={'contained'} label="Download"/>
      <Button onClick={() => generateQr(true)} size={'small'} variant={'contained'} sx={{ marginLeft: '20px' }} label="resources.qr-templates.text.preview"/>
      <AppUserCreateButton
        size={'small'}
        variant="contained"
        sx={{ marginLeft: '20px' }}
        label="resources.users.text.add"
        record={record as RaRecord}
        generateQr={generateQr}
      />
      {(downloadPoster !== false && !previewQr)
        ? <PDFDownloadLink document={<Poster posterMode={'default'} code={downloadPoster} />}>
          {
            ({
              url, loading,
            }) => {

              if (!loading && url) {

                if (!previewQr) {

                  forceDownloadPoster(url, `poster_${record.name}.pdf`);

                }

              }

              return null;

            }
          }
        </PDFDownloadLink>
        : null}
      {renderPosterQr !== false
        ? <div className="d-none">
          <QRCode includeMargin={false} bgColor={'#F1F2F2'} id="qrCodeRenderPoster" value={`https://x-guard.nl/app/register?code=${renderPosterQr.code}`} renderAs={'canvas'} size={1000} level={'H'} color="white" />
        </div> : null }
      <Dialog open={previewOpen} onClose={closePreview}>
        <DialogTitle className="flex-in-between">
          <div></div>
          <IconButton onClick={closePreview}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img src={downloadPoster?.qrCode} alt={record.name} width="500" height="500" style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}/>
          <Typography
            variant="h2"
            component="h2"
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '35px',
              marginTop: '15px',
            }}>
            {record.name}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );

};
export const TemplateList = () => {

  const roles = authProvider.getRoles();

  if (roles.includes('customer_admin')) {

    return (

      <>
        <PageExplanation text="qr-templates.text.page_info" />
        <List title="resources.qr-templates.text.title" resource="lite-templates" sx={{ paddingTop: '10px' }}
          actions={<ListActions hasCreate={false}/>}
          sort={{ field: 'name', order: 'ASC' }}
          filters={SearchFields}
        >
          <Datagrid rowClick={false} bulkActionButtons={false}>
            <TextField source="name" label="general.fields.registration_profile"/>
            <ReferenceArrayFieldNullable reference="asset-groups" source={'assetGroups'} label="menu.items.groups" sortable={false} />
            <DateFieldNullable source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
            <DateFieldNullable source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
            <QrCodeDownload label="general.text.actions"/>
          </Datagrid>
        </List>
      </>
    );

  }
  return (
    <List title="resources.qr-templates.text.title">
      <Datagrid rowClick="toggleSelection" bulkActionButtons={<></>}>
        <TextField source="name" label="general.fields.name"/>
        <DateFieldNullable source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
        <DateFieldNullable source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
        <EditButton/>
      </Datagrid>
    </List>
  );

};

const mailRecipientTypes = [
  { name: 'resources.qr-templates.text.mail_recipient_types.asset', id: 'asset' },
  { name: 'resources.qr-templates.text.mail_recipient_types.manual', id: 'manual' },
];

const pagesDefaultValue = [
  {
    name: 'register_verify',
    visible: false,
    meta: {},
  }, {
    name: 'register_available',
    visible: false,
    meta: {},
  }, {
    name: 'register_information',
    visible: false,
    meta: {},
  }, {
    name: 'register_terms',
    visible: false,
    meta: {},
  }, {
    name: 'register_privacy',
    visible: false,
    meta: {},
  },
];

const getPosterName = (code: any, downloadName = true) => {

  // loop variables, find best choice
  const variables: any = {};
  for (const variable of code.variables) {

    // set
    variables[variable.key] = variable.value.data;

  }

  // check
  let meta = `${variables.register_personal_firstname || ''} ${variables.register_personal_lastname || ''} ${variables.register_user_email || ''}`;

  // file name
  meta = meta.trim();
  if (downloadName) {

    meta = meta.replace(/\s{2,}/g, ' ');
    meta = meta.replace(/\s/g, '_');

  }

  // check
  if (meta.length === 0) {

    // do the same with the template name
    meta = code.templateName;

    // file name
    meta = meta.trim();
    if (downloadName) {

      meta = meta.replace(/\s{2,}/g, ' ');
      meta = meta.replace(/\s/g, '_');

    }

  }
  if (downloadName) {

    return meta.toLowerCase();

  } return meta;

};

const CodesList = (props: any) => {

  const translate = useTranslate();
  const [downloadPoster, setDownloadPoster] = useState<any>(false);
  const [renderPosterQr, setRenderPosterQr] = useState<any>(false);
  const [posterMode, setPosterMode] = useState('');

  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {

    setDownloadPoster(false);

  }, []);

  const record = useRecordContext();

  if (!record) {

    return null;

  }

  const forceDownloadPoster = (url: string, name: string) => {

    // create element
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloadPoster(false);

  };

  const generateQrCode = async (code: any, mode: string, templateName: string) => {

    notify('resources.qr-templates.text.generating_qr', { type: 'info' });

    // reset first
    setDownloadPoster(false);
    setRenderPosterQr(code);
    setPosterMode(mode);

    setTimeout(() => {

      setDownloadPoster({
        ...code,
        templateName: getPosterName({ ...code, templateName }, false),
        qrCode: (document.getElementById('qrCodeRenderPoster') as HTMLCanvasElement).toDataURL(),
      });

    });

  };

  const generateEmptyValue = (key:string) => {

    return {
      key,
      locked: false,
      value: { type: 'string', data: '' },
    };

  };

  const getValueByKey = (code: any, key: string) => {

    const result = _.find(code.variables, { key }) || {};
    if (Object.keys(result).length === 0) {

      return generateEmptyValue(key);

    }
    return result;

  };

  const setValueState = (value: string | boolean, setValue: any, field: any, isLockedState = false) => {

    if (isLockedState) {

      setValue({ ...field, locked: value });

    } else {

      setValue({ ...field, value: { ...field.value, data: value } });

    }

  };

  const CodeEdit = (code: any | null) => {

    code = code?.code;

    const [registerExternalId, setRegisterExternalId] = useState(getValueByKey(code, 'register_external_id'));
    const [registerFirstName, setRegisterFirstName] = useState(getValueByKey(code, 'register_personal_firstname'));
    const [registerLastName, setRegisterLastName] = useState(getValueByKey(code, 'register_personal_lastname'));
    const [registerUserEmail, setRegisterUserEmail] = useState(getValueByKey(code, 'register_user_email'));
    const [registerPersonalPhoneCountry, setRegisterPersonalPhoneCountry] = useState(getValueByKey(code, 'register_personal_phone_country'));
    const [registerPersonaPhoneNumber, setRegisterPersonaPhoneNumber] = useState(getValueByKey(code, 'register_personal_phone_number'));
    const [registerPersonalAvailable, setRegisterPersonalAvailable] = useState(_.find(code.variables, {
      key: 'register_personal_available',
    }) || {
      key: 'register_personal_available',
      locked: false,
      value: { type: 'boolean', data: false },
    });
    const [codeLimit, setCodeLimit] = useState(code.limit || 0);

    const [dialogOpen, setDialogOpen] = useState(false);

    const saveCode = async () => {

      setDialogOpen(false);

      const variableOptions = [
        registerExternalId,
        registerFirstName,
        registerLastName,
        registerUserEmail,
        registerPersonalPhoneCountry,
        registerPersonaPhoneNumber,
        registerPersonalAvailable,
      ];

      try {

        await qrCodeDataProvider.update('codes', {
          data: {
            code: code.code,
            variables: variableOptions,
            limit: (registerPersonaPhoneNumber?.value?.data?.length > 0 || registerUserEmail?.value?.data?.length > 0) ? 1 : codeLimit,
          },
        });

        notify('resources.qr-templates.text.code_updated', { type: 'success' });
        refresh();

      } catch (e) {

        console.log(e);
        notify('resources.qr-templates.text.code_updated_ failed', { type: 'error' });

      }

    };

    const deleteCode = async () => {

      setDialogOpen(false);

      try {

        await qrCodeDataProvider.delete('codes', {
          id: code.code,
        });

        notify('resources.qr-templates.text.code_deleted', { type: 'info' });
        refresh();

      } catch (e) {

        console.log(e);
        notify('resources.qr-templates.text.code_deleted_failed', { type: 'error' });

      }

    };

    return (
      <>
        <FormControl variant="standard">
          <InputLabel id="option-select-label">{translate('resources.qr-templates.text.options')}</InputLabel>
          <Select
            label={translate('resources.qr-templates.text.options')}
            id={`option-select-${code.id}`}
            autoWidth={false}
            sx={{ width: 100 }}
            labelId="option-select-label"
          >
            {code.code !== 'd4d7a38bc328b1156ba52fa8dddf734819956c9667e34be1e8b10bca91072c98'
            && <MenuItem onClick={() => setDialogOpen(true)} >Edit</MenuItem>
            }
            <MenuItem onClick={() => generateQrCode(code, 'default', record.name)} >{translate('resources.qr-templates.text.default_qr')}</MenuItem>
            <MenuItem onClick={() => generateQrCode(code, 'brochure_nl', record.name)} >{translate('resources.qr-templates.text.brochure_nl')}</MenuItem>
            <MenuItem onClick={() => generateQrCode(code, 'brochure_en', record.name)} >{translate('resources.qr-templates.text.brochure_en')}</MenuItem>
          </Select>
        </FormControl>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.field')}
                  </TableCell>
                  <TableCell>
                    {translate('resources.qr-templates.text.value')}
                  </TableCell>
                  <TableCell>
                    {translate('resources.qr-templates.text.value')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.external_id')}
                  </TableCell>
                  <TableCell>
                    <MuiTextField name='register_external_id' value={registerExternalId.value?.data} onChange={
                      (e) => setValueState(e.target.value, setRegisterExternalId, registerExternalId, false)}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_external_id_locked' checked={registerPersonalAvailable.locked} onChange={
                      (e) => setValueState(e.target.checked, setRegisterPersonalAvailable, registerPersonalAvailable, true)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.first_name')}
                  </TableCell>
                  <TableCell>
                    <MuiTextField name='register_personal_firstname' value={registerFirstName.value?.data} onChange={
                      (e) => setValueState(e.target.value, setRegisterFirstName, registerFirstName, false)}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_personal_firstname_locked' checked={registerFirstName.locked} onChange={
                      (e) => setValueState(e.target.checked, setRegisterFirstName, registerFirstName, true)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.last_name')}
                  </TableCell>
                  <TableCell>
                    <MuiTextField name='register_personal_lastname' value={registerLastName.value?.data} onChange={
                      (e) => setValueState(e.target.value, setRegisterLastName, registerLastName, false)}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_personal_lastname_locked' checked={registerLastName.locked} onChange={
                      (e) => setValueState(e.target.checked, setRegisterLastName, registerLastName, true)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.user_email')}
                  </TableCell>
                  <TableCell>
                    <MuiTextField name='register_user_email' value={registerUserEmail.value?.data} onChange={
                      (e) => setValueState(e.target.value, setRegisterUserEmail, registerUserEmail, false)}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_user_email_locked' checked={registerUserEmail.locked} onChange={
                      (e) => setValueState(e.target.checked, setRegisterUserEmail, registerUserEmail, true)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.phone_prefix')}
                  </TableCell>
                  <TableCell>
                    <MuiTextField name='register_personal_phone_country' value={registerPersonalPhoneCountry.value?.data} onChange={
                      (e) => setValueState(e.target.value, setRegisterPersonalPhoneCountry, registerPersonalPhoneCountry, false)}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_personal_phone_country_locked' checked={registerPersonalPhoneCountry.locked} onChange={
                      (e) => setValueState(e.target.checked, setRegisterPersonalPhoneCountry, registerPersonalPhoneCountry, true)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.phone_number')}
                  </TableCell>
                  <TableCell>
                    <MuiTextField name='register_personal_phone_number' value={registerPersonaPhoneNumber.value?.data} onChange={
                      (e) => setValueState(e.target.value, setRegisterPersonaPhoneNumber, registerPersonaPhoneNumber, false)}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_personal_phone_number_locked' checked={registerPersonaPhoneNumber.locked} onChange={
                      (e) => setValueState(e.target.checked, setRegisterPersonaPhoneNumber, registerPersonaPhoneNumber, true)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.available')}
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_personal_available' checked={registerPersonalAvailable.value?.data} onChange={
                      (e) => setValueState(e.target.checked, setRegisterPersonalAvailable, registerPersonalAvailable, false)}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_personal_available_locked' checked={registerPersonalAvailable.locked} onChange={
                      (e) => setValueState(e.target.checked, setRegisterPersonalAvailable, registerPersonalAvailable, true)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.limit')}
                  </TableCell>
                  <TableCell>
                    {(registerPersonaPhoneNumber?.value?.data?.length > 0 || registerUserEmail?.value?.data?.length > 0)
                      ? <MuiTextField type="number" value={1} disabled={true} />
                      : <MuiTextField type="number" value={codeLimit} onChange={(e) => setCodeLimit(e.target.value)} />
                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

          </DialogContent>
          <DialogActions>
            <Button onClick={deleteCode} color="warning" startIcon={<DeleteIcon/>} label={translate('ra.action.delete')}/>
            <SaveButton onClick={saveCode} alwaysEnable={true}/>
          </DialogActions>
        </Dialog>
      </>
    );

  };

  const CodeCreate = () => {

    const [registerExternalId, setRegisterExternalId] = useState(generateEmptyValue('register_external_id'));
    const [registerFirstName, setRegisterFirstName] = useState(generateEmptyValue('register_personal_firstname'));
    const [registerLastName, setRegisterLastName] = useState(generateEmptyValue('register_personal_lastname'));
    const [registerUserEmail, setRegisterUserEmail] = useState(generateEmptyValue('register_user_email'));
    const [registerPersonalPhoneCountry, setRegisterPersonalPhoneCountry] = useState(generateEmptyValue('register_personal_phone_country'));
    const [registerPersonaPhoneNumber, setRegisterPersonaPhoneNumber] = useState(generateEmptyValue('register_personal_phone_number'));
    const [registerPersonalAvailable, setRegisterPersonalAvailable] = useState({
      key: 'register_personal_available',
      locked: false,
      value: { type: 'boolean', data: false },
    });
    const [codeLimit, setCodeLimit] = useState(0);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const [totalToRegister, setTotalToRegister] = useState(0);
    const [isRegistering, setIsRegistering] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const normalise = (value: number, max: number) => ((value) * 100) / (max);

    const recordId: Identifier = useGetRecordId();

    if (!recordId || recordId === '') {

      return <span>{translate('resources.qr-templates.text.save_first')}</span>;

    }

    const saveCode = async () => {

      setDialogOpen(false);

      const variableOptions = [
        registerExternalId,
        registerFirstName,
        registerLastName,
        registerUserEmail,
        registerPersonalPhoneCountry,
        registerPersonaPhoneNumber,
        registerPersonalAvailable,
      ];

      try {

        await qrCodeDataProvider.create('codes', {
          data: {
            codeTemplate: recordId,
            variables: variableOptions,
            limit: (registerPersonaPhoneNumber?.value?.data?.length > 0 || registerUserEmail?.value?.data?.length > 0) ? 1 : codeLimit,
          },
        });

        notify('resources.qr-templates.text.code_created', { type: 'success' });
        refresh();

      } catch (e) {

        console.log(e);
        notify('resources.qr-templates.text.code_created_failed', { type: 'error' });

      }

    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

      if (!e.target.files) {

        return;

      }

      const file = e.target.files[0];
      const fileString = await file.text();
      let encoding;
      try {

        encoding = jschardet.detect(fileString);

      } catch {

        encoding = { encoding: 'utf-8' };

      }

      // parse the uploaded file
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        encoding: encoding.encoding,
        async complete(results: { data: UploadedCSV[] }) {

          notify('resources.qr-templates.text.uploading', { type: 'info' });

          const usersToRegister = [];

          // start the validation
          const errors = await validateUploadedCsv(results.data);
          if (errors.length > 0) {

            notify(<div style={{
              backgroundColor: 'red', color: 'white', padding: '10px', borderRadius: '5px',
            }}>{errors.map((error, i) => {

                return <div key={i}>{error}</div>;

              })}</div>, { type: 'error' });
            return;

          }
          for (const uploadedCode of results.data) {

            const code = {
              codeTemplate: recordId,
              variables: [
                {
                  key: 'register_username',
                  locked: true,
                  value: { type: 'string', data: uploadedCode.value_user_username.toLowerCase() },
                },
                {
                  key: 'register_external_id',
                  locked: uploadedCode.locked_external_id.toLowerCase() === 'true',
                  value: { type: 'string', data: uploadedCode.value_external_id },
                },
                {
                  key: 'register_personal_firstname',
                  locked: uploadedCode.locked_personal_firstname.toLowerCase() === 'true',
                  value: { type: 'string', data: uploadedCode.value_personal_firstname },
                },
                {
                  key: 'register_personal_lastname',
                  locked: uploadedCode.locked_personal_lastname.toLowerCase() === 'true',
                  value: { type: 'string', data: uploadedCode.value_personal_lastname },
                },
                {
                  key: 'register_personal_phone_country',
                  locked: uploadedCode.locked_personal_phone_country.toLowerCase() === 'true',
                  value: { type: 'string', data: uploadedCode.value_personal_phone_country },
                },
                {
                  key: 'register_personal_phone_number',
                  locked: uploadedCode.locked_personal_phone_number.toLowerCase() === 'true',
                  value: { type: 'string', data: uploadedCode.value_personal_phone_number },
                },
                {
                  key: 'register_user_email',
                  locked: uploadedCode.locked_user_email.toLowerCase() === 'true',
                  value: { type: 'string', data: uploadedCode.value_user_email },
                },
                {
                  key: 'register_personal_available',
                  locked: uploadedCode.locked_personal_available.toLowerCase() === 'true',
                  value: { type: 'boolean', data: uploadedCode.value_personal_available.toLowerCase() === 'true' },
                },
                {
                  key: 'register_locale',
                  locked: uploadedCode.locked_personal_locale.toLowerCase() === 'true',
                  value: { type: 'string', data: uploadedCode.value_personal_locale },
                },
                {
                  key: 'register_timezone',
                  locked: uploadedCode.locked_personal_timezone.toLowerCase() === 'true',
                  value: { type: 'string', data: uploadedCode.value_personal_timezone },
                },
              ],
              limit: (uploadedCode.value_personal_phone_number.length > 0 || uploadedCode.value_user_email.length > 0) ? 1 : 0,
            };

            const createResult = await qrCodeDataProvider.create('codes', { data: code });

            if (uploadedCode.download_after_import.toLowerCase() === 'true') {

              await generateQrCode(createResult.data, 'default', record.name);
              await wait(500);

            }
            if (uploadedCode.register_after_import.toLowerCase() === 'true') {

              usersToRegister.push(createResult.data);

            }

          }

          if (usersToRegister.length > 0) {

            setIsRegistering(true);
            notify('Users registering, check the downloaded CSV(s) for more info, please wait...', { type: 'info' });
            setDialogOpen(false);
            await RegisterUsers(usersToRegister, setProgress, setTotalToRegister);
            setIsRegistering(false);
            notify('Users registered', { type: 'success' });

          }

          refresh();
          setDialogOpen(false);

        },
      });

    };

    return (
      <>
        <IconButton onClick={() => setDialogOpen(true)}><AddIcon/></IconButton>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>

          <DialogContent>
            <div className="flex-in-between">

              <Typography>{translate('general.text.upload_file')}</Typography>
              <input
                accept=".csv"
                style={{ display: 'none' }}
                id="code_upload_file"
                type="file"
                ref={inputRef}
                onChange={handleFileChange}
              />
              <label htmlFor="code_upload_file">
                <IconButton component="span"><FileUploadIcon/></IconButton>
              </label>
            </div>
            <span style={{
              cursor: 'pointer', color: 'blue', textDecoration: 'underline', fontSize: '10px',
            }}
            onClick={() => downloadEmptyCSV()}
            >
                ( download empty CSV )
            </span>
            <Typography>
              Or, create one manually:
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.field')}
                  </TableCell>
                  <TableCell>
                    {translate('resources.qr-templates.text.value')}
                  </TableCell>
                  <TableCell>
                    {translate('resources.qr-templates.text.locked')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.external_id')}
                  </TableCell>
                  <TableCell>
                    <MuiTextField name='register_external_id' value={registerExternalId.value?.data} onChange={
                      (e) => setValueState(e.target.value, setRegisterExternalId, registerExternalId, false)}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_external_id_locked' checked={registerPersonalAvailable.locked} onChange={
                      (e) => setValueState(e.target.checked, setRegisterPersonalAvailable, registerPersonalAvailable, true)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.first_name')}
                  </TableCell>
                  <TableCell>
                    <MuiTextField name='register_personal_firstname' value={registerFirstName.value?.data} onChange={
                      (e) => setValueState(e.target.value, setRegisterFirstName, registerFirstName, false)}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_personal_firstname_locked' checked={registerFirstName.locked} onChange={
                      (e) => setValueState(e.target.checked, setRegisterFirstName, registerFirstName, true)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.last_name')}
                  </TableCell>
                  <TableCell>
                    <MuiTextField name='register_personal_lastname' value={registerLastName.value?.data} onChange={
                      (e) => setValueState(e.target.value, setRegisterLastName, registerLastName, false)}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_personal_lastname_locked' checked={registerLastName.locked} onChange={
                      (e) => setValueState(e.target.checked, setRegisterLastName, registerLastName, true)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.user_email')}
                  </TableCell>
                  <TableCell>
                    <MuiTextField name='register_user_email' value={registerUserEmail.value?.data} onChange={
                      (e) => setValueState(e.target.value, setRegisterUserEmail, registerUserEmail, false)}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_user_email_locked' checked={registerUserEmail.locked} onChange={
                      (e) => setValueState(e.target.checked, setRegisterUserEmail, registerUserEmail, true)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.phone_prefix')}
                  </TableCell>
                  <TableCell>
                    <MuiTextField name='register_personal_phone_country' value={registerPersonalPhoneCountry.value?.data} onChange={
                      (e) => setValueState(e.target.value, setRegisterPersonalPhoneCountry, registerPersonalPhoneCountry, false)}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_personal_phone_country_locked' checked={registerPersonalPhoneCountry.locked} onChange={
                      (e) => setValueState(e.target.checked, setRegisterPersonalPhoneCountry, registerPersonalPhoneCountry, true)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.phone_number')}
                  </TableCell>
                  <TableCell>
                    <MuiTextField name='register_personal_phone_number' value={registerPersonaPhoneNumber.value?.data} onChange={
                      (e) => setValueState(e.target.value, setRegisterPersonaPhoneNumber, registerPersonaPhoneNumber, false)}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_personal_phone_number_locked' checked={registerPersonaPhoneNumber.locked} onChange={
                      (e) => setValueState(e.target.checked, setRegisterPersonaPhoneNumber, registerPersonaPhoneNumber, true)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.available')}
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_personal_available' checked={registerPersonalAvailable.value?.data} onChange={
                      (e) => setValueState(e.target.checked, setRegisterPersonalAvailable, registerPersonalAvailable, false)}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiSwitch name='register_personal_available_locked' checked={registerPersonalAvailable.locked} onChange={
                      (e) => setValueState(e.target.checked, setRegisterPersonalAvailable, registerPersonalAvailable, true)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {translate('resources.qr-templates.text.limit')}
                  </TableCell>
                  <TableCell>
                    {(registerPersonaPhoneNumber?.value?.data?.length > 0 || registerUserEmail?.value?.data?.length > 0)
                      ? <MuiTextField type="number" value={1} disabled={true}/>
                      : <MuiTextField type="number" value={codeLimit} onChange={(e) => setCodeLimit(parseInt(e.target.value, 10))} />
                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

          </DialogContent>
          <DialogActions>
            <SaveButton onClick={saveCode} alwaysEnable={true}/>
          </DialogActions>
        </Dialog>
        <Dialog open={isRegistering} maxWidth="md" fullWidth>
          <DialogTitle><span>{translate('resources.qr-templates.text.registering_title')}</span></DialogTitle>

          <DialogContent>
            {(progress === 0 && totalToRegister === 0)
              && (
                <Loading loadingSecondary=""/>
              )}
            {totalToRegister !== 0
              && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate"
                      value={normalise(progress, totalToRegister)}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${progress}/${totalToRegister}`}</Typography>
                  </Box>
                </Box>
              )}
          </DialogContent>
        </Dialog>
      </>
    );

  };

  const codes: any[] = get(record, props.source) || [];

  const tableRows: any[] = [];

  codes.forEach((code: any, index) => {

    let codeType = 'default';

    if (code.variables.length > 0) {

      if (_.find(code.variables, { key: 'register_user_email' })?.value?.data) {

        codeType = _.find(code.variables, { key: 'register_user_email' }).value.data;

      } else if (_.find(code.variables, { key: 'register_personal_phone_number' })?.value?.data) {

        codeType = `${_.find(code.variables, { key: 'register_personal_phone_country' }).value.data}
        ${_.find(code.variables, { key: 'register_personal_phone_number' }).value.data}`;

      }

    }

    tableRows.push((
      <TableRow>
        <TableCell>
          #{index + 1}
        </TableCell>
        <TableCell>
          {codeType}
        </TableCell>
        <TableCell>
          {code.used} / {code.limit}
        </TableCell>
        <TableCell>
          <CodeEdit code={code}/>
        </TableCell>
      </TableRow>
    ));

  });

  return (
    <>
      <Table>
        <TableHead>
          <TableCell colSpan={2}>
            {translate('resources.qr-templates.text.codes')}
          </TableCell>
          <TableCell colSpan={2} sx={{ textAlign: 'right' }}>
            <CodeCreate/>
          </TableCell>
        </TableHead>
        <TableBody>
          {tableRows}
        </TableBody>
      </Table>
      {downloadPoster !== false
        ? <PDFDownloadLink document={<Poster posterMode={posterMode} code={downloadPoster} />}>
          {
            ({
              url, loading,
            }) => {

              if (!loading && url) {

                forceDownloadPoster(url, `poster_${getPosterName(downloadPoster)}.pdf`);

              }

              return null;

            }
          }
        </PDFDownloadLink>
        : null}
      {renderPosterQr !== false
        ? <div className="d-none">
          <QRCode includeMargin={false} bgColor={'#F1F2F2'} id="qrCodeRenderPoster" value={`https://x-guard.nl/app/register?code=${renderPosterQr.code}`} renderAs={'canvas'} size={posterMode === 'default' ? 1000 : 1000} level={posterMode === 'default' ? 'H' : 'H' } color="white" />
        </div> : null }
    </>
  );

};

const ActionField = (props: any) => {

  const { record } = useEditContext();
  const context = useSimpleFormIteratorItem();
  const source = `actions.${context.index}`;

  const action = get(record, source);

  if (!action) {

    return null;

  }
  switch (action.actionTemplate?.functionName) {

  case 'user_restriction_group':

    return (
      <>
        <TextInput source={'actionTemplate.functionName'} label={false} disabled/>
        <ReferenceArrayInput {...props} source={'xgacValue'} reference="asset-groups">
          <AutocompleteArrayInput label="resources.qr-templates.text.value" defaultValue={[]}/>
        </ReferenceArrayInput>
      </>
    );
  case 'asset_group':

    return (
      <>
        <TextInput source={'actionTemplate.functionName'} label={false} disabled/>
        <ReferenceArrayInput {...props} source={'xgacValue'} reference="asset-groups">
          <AutocompleteArrayInput label="resources.qr-templates.text.value" defaultValue={[]} autoHighlight blurOnSelect={false} />
        </ReferenceArrayInput>
      </>
    );
  case 'config_group':
    return (
      <>
        <TextInput source={'actionTemplate.functionName'} label={false} disabled/>
        <ArrayInput source={'xgacValue'} label="resources.qr-templates.fields.group_list">
          <AutocompleteArrayInput choices={props.availableGroups} optionValue="value" optionText="label" autoHighlight
            blurOnSelect={false}
            label="resources.qr-templates.text.value"
          />
        </ArrayInput>
      </>
    );
  case 'email':
    return (
      <>
        <TextInput source={'actionTemplate.functionName'} label={false} disabled/>
        <SelectInput source={'xgacValue.recipientType'} label="resources.qr-templates.fields.recipient_type"
          choices={mailRecipientTypes}/>
        <TextInput source={'xgacValue.template.label'} label="resources.qr-templates.fields.template_label"/>
        <TextInput source={'xgacValue.template.value'} label="resources.qr-templates.fields.template_value"/>
        <ArrayInput source={'xgacValue.recipientList'} label="resources.qr-templates.fields.recipient_list">
          <SimpleFormIterator>
            <TextInput source="label" label="Email"/>
          </SimpleFormIterator>
        </ArrayInput>
      </>
    );
  default:
    return null;

  }

};

export const TemplateEdit = () => {

  const isCustomerAdmin = authProvider.isCustomerAdmin();
  const redirect = useRedirect();

  if (isCustomerAdmin) {

    redirect('/templates');

  }

  const recordId = useGetRecordId();
  const notify = useNotify();
  const refresh = useRefresh();
  const [availableGroups, setAvailableGroups] = useState<any[]>([]);

  useEffect(() => {

    const getAvailableGroups = async () => {

      const groupRequest = await httpClient(`${deviceRegistrationApiUrl}/v3/deployment/device/groups`);
      setAvailableGroups(groupRequest.json.groups.map((group: string) => ({ value: group, label: group })));

    };

    getAvailableGroups();

  }, []);

  const transform = (data: Record<string, any>) => {

    return {
      name: data.name,
      pages: data.pages,
    };

  };
  const saveQrTemplate = (props: any) => {

    const actions = props.actions;

    const actionPromises = [];
    for (const action of actions) {

      if (action.id) {

        actionPromises.push(qrCodeDataProvider.update('actions', {
          id: action.id,
          data: {
            xgacValue: action.xgacValue,
          },
          meta: {
            functionName: action.actionTemplate.functionName,
          },
        }));

      } else {

        actionPromises.push(qrCodeDataProvider.create('actions', {
          data: {
            codeTemplate: recordId,
            actionTemplate: action.actionTemplate.functionName,
            xgacValue: action.xgacValue,
          },
          meta: {
            functionName: action.actionTemplate.functionName,
          },
        }));

      }

    }
    Promise.all(actionPromises);
    const translated = transform(props);
    try {

      qrCodeDataProvider.update('templates', { id: recordId, data: translated });
      notify('resources.qr-templates.fields.template_updated', { type: 'success' });
      refresh();

    } catch (e) {

      console.log(e);
      notify('resources.qr-templates.fields.template_update_failed', { type: 'error' });

    }

  };

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Unstable}/>
      <EditWithSuccess actions={false} component="div" sx={{ width: '100%' }} title="resources.qr-templates.text.title" transform={transform}>
        <SimpleForm sx={{ width: '100%' }} onSubmit={saveQrTemplate}>
          <Grid container spacing={3}>
            <Grid xs={6} md={6}>
              <Card sx={{ margin: 1 }}>
                <CardContent>
                  <TextInput name="name" source="name" fullWidth label="general.fields.name"/>
                </CardContent>
              </Card>
              <Card sx={{ margin: 1 }}>
                <CardContent>
                  <CodesList source="codes" label="resources.qr-templates.text.codes"/>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={6} md={6}>
              <Card sx={{ margin: 1 }}>
                <CardContent>
                  <ArrayInput name='pages' source="pages" label="resources.qr-templates.fields.pages">
                    <SimpleFormIterator disableAdd disableRemove disableReordering>
                      <TextInput source='name' disabled fullWidth label="general.fields.name"/>
                      <BooleanInput source='visible' label="resources.qr-templates.fields.visible"/>
                    </SimpleFormIterator>
                  </ArrayInput>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} md={12}>
              <Card sx={{ margin: 1 }}>
                <CardContent>
                  <ArrayInput name="actions" source='actions' label="resources.qr-templates.fields.actions">
                    <SimpleFormIterator disableAdd disableRemove disableReordering>
                      <ActionField availableGroups={availableGroups}/>
                    </SimpleFormIterator>
                  </ArrayInput>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};

export const TemplateCreate = () => {

  const isCustomerAdmin = authProvider.isCustomerAdmin();
  const redirect = useRedirect();

  if (isCustomerAdmin) {

    redirect('/templates');

  }

  const translate = useTranslate();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Beta}/>
      <Create redirect="edit" component="div" title="resources.qr-templates.text.title">
        <SimpleForm>
          <Grid container spacing={3}>
            <Grid xs={6} md={6}>
              <Card sx={{ margin: 1 }}>
                <CardContent>
                  <TextInput name="name" source="name" fullWidth label="general.fields.name"/>
                </CardContent>
              </Card>
              <Card sx={{ margin: 1 }}>
                <CardContent>
                  {translate('resources.qr-templates.text.save_first')}
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={6} md={6}>
              <Card sx={{ margin: 1 }}>
                <CardContent>
                  <ArrayInput name='pages' source="pages" defaultValue={pagesDefaultValue} label="resources.qr-templates.fields.pages">
                    <SimpleFormIterator disableAdd disableRemove disableReordering>
                      <TextInput source='name' disabled fullWidth label="general.fields.name"/>
                      <BooleanInput source='visible' label="resources.qr-templates.fields.visible"/>
                    </SimpleFormIterator>
                  </ArrayInput>
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={12} md={12}>
              <Card sx={{ margin: 1 }}>
                <CardContent>
                  {translate('resources.qr-templates.text.actions_save_first')}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </>
  );

};
