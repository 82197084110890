import {
  Button,
  FilterButton,
  Labeled,
  Loading,
  TopToolbar,
  useDeleteMany,
  useGetList,
  useNotify,
  useRefresh,
  useTranslate,
  useUnselectAll,
} from 'react-admin';
import * as React from 'react';
import { SetStateAction, useEffect, useState } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { useListContext } from 'ra-core';
import DeleteIcon from '@mui/icons-material/Delete';
import { IfCanAccess } from '@react-admin/ra-rbac';
import GroupsIcon from '@mui/icons-material/Groups';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import authProvider from '../../utils/authProvider';
import { CustomBulkExportButton, FullExportButton } from '../../utils/customExporter';
import { groupAndConfigSetter, QrAction } from '../../lib/groupAndConfigSetter';
import { wait } from '../../lib/websockets/webSocketHelpers';
import { getCurrentCustomer } from '../../lib/currentCustomer';
import { xgacDataProvider } from '../../dataProviders/xgacDataProvider';
import ReactSelect from '../../lib/react-select/reactSelect';
import { httpClient } from '../../utils/httpClient';
import { DEVICE_REGISTRATION_API_URL } from '../../config';
import { AppUserCreateButton } from '../../apps/xgac/components/buttons/AppUserCreateButton';

const deviceRegistrationApiUrl = DEVICE_REGISTRATION_API_URL;
export const GroupActionAppendReplaceButton = (props: { label: string; value: string; setValue: SetStateAction<any>; disableSkip?: boolean }) => {

  const translate = useTranslate();

  return (
    <div>
      <Labeled label={props.label}>
        <RadioGroup
          row
          aria-labelledby={props.label}
          defaultValue="append"
          name={props.label}
          onChange={(event) => props.setValue(event.target.value)}
          value={props.value}
        >
          <FormControlLabel value={QrAction.replace} control={<Radio/>} label={translate('general.text.replace')}/>
          <FormControlLabel value={QrAction.append} control={<Radio/>} label={translate('general.text.append')}/>
          {!props.disableSkip
          && <FormControlLabel value={QrAction.skip} control={<Radio/>} label={translate('general.text.skip')}/>
          }
        </RadioGroup>
      </Labeled>
    </div>
  );

};

export const AppUserActionButtons = () => {

  return (
    <TopToolbar>
      <FilterButton/>
      <AppUserCreateButton/>
      <FullExportButton resource={'app-users'}/>
    </TopToolbar>
  );

};

const QrSettingsDialog = (props: {
  customer?: { name: string; value: string };
  dialogOpen: boolean;
  setDialogOpen: SetStateAction<any>;
  setUserCustomer?: SetStateAction<any>;
}) => {

  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const { selectedIds } = useListContext();
  const [templateChoices, setTemplateChoices] = useState<{ id: string; label: string }[]>([]);
  const [groupChoices, setGroupChoices] = useState<{ id: string; label: string }[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<{ id: string; label: string }[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<{ id: string; label: string } | null>(null);
  const [groupResponseLoading, setGroupResponseLoading] = useState(false);
  const [templateGroupAndConfig, setTemplateGroupAndConfig] = useState<any>({});
  const [groupAction, setGroupAction] = useState<QrAction>(QrAction.append);
  const [configAction, setConfigAction] = useState<QrAction>(QrAction.append);
  const [availableConfigGroups, setAvailableConfigGroups] = useState<{ id: string; label: string }[]>([]);
  const [selectedConfigGroups, setSelectedConfigGroups] = useState<{ id: string; label: string }[]>([]);
  const { data, isLoading, refetch } = useGetList('templates', { pagination: { perPage: 100, page: 1 }, meta: { customer: props.customer } });
  const { data: assetGroupData, isLoading: assetGroupIsLoading, refetch: assetGroupRefetch } = useGetList('asset-groups', {
    pagination: { perPage: 100, page: 1 },
    meta: { customer: props.customer },
  });

  useEffect(() => {

    const getGroups = async () => {

      const groupsRequest = await httpClient(`${deviceRegistrationApiUrl}/v3/deployment/device/groups`);
      setAvailableConfigGroups(groupsRequest.json?.groups?.map((group: any) => ({ id: group, label: group })));

    };
    getGroups();

  }, []);
  useEffect(() => {

    refetch();
    assetGroupRefetch();

  }, [props.customer, refetch, assetGroupRefetch]);
  const handleClose = () => {

    props.setDialogOpen(false);
    setGroupResponseLoading(false);
    setSelectedTemplate(null);
    setSelectedGroups([]);
    setSelectedConfigGroups([]);
    setGroupAction(QrAction.append);
    setConfigAction(QrAction.append);

  };

  useEffect(() => {

    if (!isLoading && data && data?.length > 0) {

      setTemplateChoices(data?.map((template: any) => ({ id: template.id, label: template.name })));

    }

  }, [data, isLoading, templateGroupAndConfig]);

  useEffect(() => {

    if (!assetGroupIsLoading && assetGroupData && assetGroupData?.length > 0) {

      setGroupChoices(assetGroupData?.map((group: any) => ({ id: group._id, label: group.name })));
      setSelectedGroups([]);

    }

  }, [assetGroupData, assetGroupIsLoading]);

  useEffect(() => {

    const temporaryTemplateGroupAndConfig:any = {};
    if (isLoading || assetGroupIsLoading || !data || !assetGroupData) {

      return;

    }

    for (const template of data) {

      const groupInTemplateRaw = template?.actions.find((action: any) => action.actionTemplate === 'asset_group') || { xgacValue: [] };
      const configInTemplateRaw = template?.actions.find((action: any) => action.actionTemplate === 'config_group') || { xgacValue: [] };
      const groupNamesInTemplate = groupInTemplateRaw?.xgacValue?.map((group: string) => {

        const groupObject = assetGroupData?.find((assetGroup: any) => assetGroup._id === group);
        return { id: groupObject?._id, label: groupObject?.name };

      }).filter((group: any) => group.id !== undefined);
      if (!Array.isArray(configInTemplateRaw.xgacValue || [])) {

        configInTemplateRaw.xgacValue = [];

      }
      let usedConfig = configInTemplateRaw?.xgacValue || configInTemplateRaw?.value || [];
      if (!Array.isArray(usedConfig)) {

        usedConfig = [usedConfig];

      }
      const configNamesInTemplate = usedConfig.map((value: string) => {

        return { id: value, label: value };

      });

      temporaryTemplateGroupAndConfig[template.id] = { groups: groupNamesInTemplate, configs: configNamesInTemplate };

    }
    setTemplateGroupAndConfig(temporaryTemplateGroupAndConfig);

  }, [data, isLoading, assetGroupData, assetGroupIsLoading, availableConfigGroups]);

  const handleSelectTemplate = (event: any, newValue: { id: string; label: string } | null) => {

    setSelectedTemplate(newValue);
    if (newValue) {

      setSelectedGroups(templateGroupAndConfig[newValue?.id].groups || []);
      setSelectedConfigGroups(templateGroupAndConfig[newValue?.id].configs || []);

    }

  };

  const handleSettings = async () => {

    setGroupResponseLoading(true);
    await groupAndConfigSetter(
      selectedIds,
      groupAction,
      configAction,
      selectedGroups?.map((group: any) => group.id),
      selectedConfigGroups?.map((group: any) => group.id),
    );
    setGroupResponseLoading(false);
    handleClose();
    if (props.setUserCustomer) {

      props.setUserCustomer({ name: '', value: '' });

    }
    await wait(100);
    notify('resources.app-users.text.config_and_groups_updated', { type: 'success' });
    refresh();

  };

  return (
    <Dialog open={props.dialogOpen} onClose={handleClose} fullWidth maxWidth="md">
      {!groupResponseLoading && (
        <>
          <DialogTitle>{translate('resources.app-users.text.group_and_config_button_title')}</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              {translate('resources.app_users.text.groups_and_config_explanation')}
            </Typography>
            <MuiAutocomplete
              renderInput={(params) => <MuiTextField {...params}
                label={translate('resources.qr-templates.text.single_title')}/>}
              options={templateChoices}
              value={selectedTemplate}
              onChange={handleSelectTemplate}
              sx={{ marginBottom: '10px' }} />
            <MuiAutocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              size={'small'}
              multiple
              renderInput={(params) => <MuiTextField {...params}
                label={translate('resources.app-users.text.group_title')} />}
              options={groupChoices}
              value={selectedGroups}
              onChange={(event, newValue) => setSelectedGroups(newValue)} />
            <MuiAutocomplete
              isOptionEqualToValue={(option, value) => option.id === value.id}
              size={'small'}
              multiple
              renderInput={(params) => <MuiTextField {...params}
                label={translate('resources.app-users.text.config')} />}
              options={availableConfigGroups}
              value={selectedConfigGroups}
              onChange={(event, newValue) => setSelectedConfigGroups(newValue)}
              sx={{ marginBottom: '10px' }} />
            <GroupActionAppendReplaceButton label={translate('resources.asset-groups.name')} value={groupAction} setValue={setGroupAction} />
            <GroupActionAppendReplaceButton label={translate('resources.app-users.text.config')} value={configAction} setValue={setConfigAction} />
          </DialogContent><DialogActions>
            <Button onClick={handleClose} label="ra.action.close" />
            <Button onClick={handleSettings} label="ra.action.confirm" />
          </DialogActions>
        </>
      )}
      {groupResponseLoading && (
        <Loading loadingSecondary={''}/>
      )}
    </Dialog>
  );

};

const QrSettingsButton = () => {

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button label={'resources.app-users.text.group_and_config_button_title'} startIcon={<GroupsIcon/>} onClick={() => setDialogOpen(true)}/>
      <QrSettingsDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen}/>
    </>
  );

};

export const MoveUserButton = () => {

  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [qrSettingsOpen, setQrSettingsOpen] = useState(false);
  const [userCustomer, setUserCustomer] = useState({ name: '', value: '' });
  const unselectAll = useUnselectAll('app-users');

  const notify = useNotify();
  const refresh = useRefresh();

  const handleClickOpen = () => {

    setOpen(true);

  };
  const handleClose = () => {

    setOpen(false);

  };

  const changeUserCustomer = (event: { name: string; value: string }) => {

    setUserCustomer(event);

  };

  const {
    selectedIds,
    resource,
  } = useListContext();

  const customersString = localStorage.getItem('customers');
  if (!customersString) {

    return null;

  }
  const customers = JSON.parse(customersString);
  const currentCustomer = getCurrentCustomer();

  if (!currentCustomer) {

    return null;

  }
  const invalidStatus = (inputStatus: number) => inputStatus >= 400;

  const moveUsers = async () => {

    let moveResultStatuses: number[] = [];
    if (resource === 'app-users') {

      moveResultStatuses = await xgacDataProvider.moveAppUsers(selectedIds, userCustomer.value, currentCustomer.value);

    }

    setOpen(false);
    if (moveResultStatuses.length > 0 && moveResultStatuses.some(invalidStatus)) {

      notify('general.text.move_users_failed', { type: 'error' });

    } else {

      notify('general.text.move_users_success', { type: 'success' });
      setQrSettingsOpen(true);

    }

    unselectAll();
    refresh();

  };

  return (
    <><Button onClick={handleClickOpen} label="general.text.move_users_title" startIcon={<ShortcutIcon/>}/>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            overflowY: 'visible',
          },
        }}
      >
        <DialogTitle>
          {translate('general.text.move_users_explanation')}
        </DialogTitle>
        <DialogContent style={{ overflowY: 'visible' }}>
          <DialogContentText>
            <ReactSelect
              onChange={changeUserCustomer}
              options={customers}
              defaultValue={currentCustomer}
            />

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            <>{translate('ra.action.cancel')}</>
          </Button>
          <Button onClick={moveUsers}>
            <>{translate('general.text.move')}</>
          </Button>
        </DialogActions>
      </Dialog>
      {userCustomer.value !== ''
      && <QrSettingsDialog dialogOpen={qrSettingsOpen} setDialogOpen={setQrSettingsOpen} customer={userCustomer}/>
      }
    </>);

};

export const AppUserBulkActionButtons = () => {

  const translate = useTranslate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteAllDevices, setDeleteAllDevices] = useState(false);
  const notify = useNotify();
  const unselectAll = useUnselectAll('app-users');
  const DeleteAppUserButton = () => {

    const { selectedIds } = useListContext();
    const [deleteMany, { isLoading, error }] = useDeleteMany(
      'app-users',
      { ids: selectedIds, meta: { deleteAllDevices } },
    );

    const deleteAppUsers = () => {

      deleteMany();
      unselectAll();
      setDialogOpen(false);

      if (!isLoading && !error) {

        notify('resources.app-users.text.delete_success', { type: 'info' });

      }

    };
    if (error) {

      notify('resources.app-users.text.delete_failed', { type: 'warning' });

    }

    return <Button onClick={deleteAppUsers} color="warning" startIcon={<DeleteIcon/>} label={'ra.action.delete'}/>;

  };

  const isAdmin = authProvider.isAdmin();

  return (
    <>
      {(isAdmin)
        && (
          <><QrSettingsButton/><MoveUserButton/></>
        )
      }
      <IfCanAccess action="export">
        <CustomBulkExportButton resource='app-users'/>
      </IfCanAccess>
      <IfCanAccess action='delete'>
        <Button onClick={() => setDialogOpen(true)} color="warning" startIcon={<DeleteIcon/>} label={'ra.action.delete'}/>
      </IfCanAccess>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          {translate('resources.app-users.text.delete_title')}
        </DialogTitle>
        <DialogContent>
          <FormControlLabel control={
            <Checkbox checked={deleteAllDevices} onChange={() => setDeleteAllDevices(!deleteAllDevices)}/>
          } label={translate('resources.app-users.text.delete_all_devices')}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} autoFocus>
            <>{translate('ra.action.cancel')}</>
          </Button>
          <DeleteAppUserButton/>
        </DialogActions>
      </Dialog>
    </>
  );

};
