import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import { IfCanAccess } from '@react-admin/ra-rbac';
import React from 'react';
import { LIST_DEBOUNCE } from '../../../config';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import { exporter } from '../../../utils/customExporter';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';

export const DomainlinkList = () => {

  const domainFilters = [
    <SearchInput source="domain" alwaysOn sx={{
      '& .MuiFilledInput-input': {
        height: '31px',
      },
      width: '100%',
    }} variant="filled"/>,
    <ReferenceInput source="xgacCustomerId" reference="customers" alwaysOn>
      <AutocompleteInput label="resources.alarms.fields.customer._id" fullWidth size={'small'}/>
    </ReferenceInput>,
  ];
  return (
    <List filters={domainFilters} debounce={LIST_DEBOUNCE} title="resources.domainlinks.title" exporter={exporter}>
      <Datagrid rowClick="toggleSelection" bulkActionButtons={<CustomBulkActionButtons/>}>
        <TextField source="domain" label="resources.domainlink.fields.domain" />
        <ReferenceField source="xgacCustomerId" reference="customers" label="resources.alarms.fields.customer._id" />
        <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
        <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
        <IfCanAccess action="edit">
          <EditButton/>
        </IfCanAccess>
      </Datagrid>
    </List>
  );

};

export const DomainlinkEdit = () => {

  return (
    <EditWithSuccess title="resources.domainlinks.title" actions={false}>
      <SimpleForm>
        <ReferenceInput source="xgacCustomerId" reference="customers" >
          <AutocompleteInput label="resources.alarms.fields.customer._id" fullWidth disabled/>
        </ReferenceInput>
        <TextInput source="domain" label="resources.domainlink.fields.domain" validate={required()} />
      </SimpleForm>
    </EditWithSuccess>
  );

};

export const DomainlinkCreate = () => {

  return (
    <CreateWithSuccess title="resources.domainlinks.title">
      <SimpleForm>
        <ReferenceInput source="xgacCustomerId" reference="customers" >
          <AutocompleteInput label="resources.alarms.fields.customer._id" fullWidth validate={required()} />
        </ReferenceInput>
        <TextInput source="domain" label="resources.domainlink.fields.domain" validate={required()} />
      </SimpleForm>
    </CreateWithSuccess>
  );

};
